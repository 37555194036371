/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/m/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/m/_next/static/media/slick.25572f22.eot);
    src: url(/m/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/m/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/m/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/m/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

.myevals-react-frontend__placeholder {
  position: relative;
  background-color: rgb(0 0 0 / 20%);
  overflow: hidden;
}

.myevals-react-frontend__placeholder::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    90deg,
    rgb(0 0 0 / 0%) 20%,
    rgb(0 0 0 / 10%) 40%,
    rgb(0 0 0 / 0%) 60%
  );
  animation: myevals-react-frontend__placeholder__gradient 1.5s infinite linear;
}

@keyframes myevals-react-frontend__placeholder__gradient {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(150%);
  }
}

.myevals-react-frontend__spinner {
  animation: myevals-react-frontend__spinner-animation 1s infinite linear;
}

@keyframes myevals-react-frontend__spinner-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* stylelint-disable selector-pseudo-class-no-unknown */

.Combobox_wrapper__xAaa2 {
  position: relative;
}

.Combobox_input__KQIAY {
  box-sizing: border-box;
}

.Combobox_leftIcon__ZQMQ_:empty {
  display: none;
}

.Combobox_rightIcon__8N6kQ:empty {
  display: none;
}

.Combobox_input__KQIAY:focus + .Combobox_arrowIcon__d4BE_ {
  pointer-events: none;
}

.Combobox_rotate__n5ixb {
  transform: rotate(180deg) translateY(5px);
}

/* Clip-path to get rid of overflowing box shadow on input */
.Combobox_options__u6pME {
  list-style: none;
  scrollbar-width: thin;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: opacity 0.1s ease-in;
  position: absolute;
  top: 100%;
  margin: 0;
  -webkit-clip-path: inset(0 -48px -48px -48px);
          clip-path: inset(0 -48px -48px -48px);
}

.Combobox_options__u6pME.hide-start {
  opacity: 1;
}

.Combobox_options__u6pME.hide-end {
  opacity: 0;
}

.DialogV2_overlay__uESao {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
}


.FollowingTooltip_container__qAKIx {
  position: relative;
  min-width: min-content;
}

.FollowingTooltip_tooltip__zhDeI:empty {
  display: none;
}

.Switch_root__WVwrB {
	position: relative;
}

.Switch_thumb__Q9stZ {
	display: block;
	will-change: transform;
}

.HiddenIfEmptyContainer_main__t70vo:empty {
  display: none !important;
}


.CheckboxOrRadio_container__rreqK {
  position: relative;
}

.CheckboxOrRadio_input___7u3G {
  opacity: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  inset: 0;
  cursor: inherit;
}


.ToastContextProvider_viewport___wNRW {
  --viewport-padding-horizontal: 16px;
  --viewport-padding-vertical: 16px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--viewport-padding-vertical) var(--viewport-padding-horizontal);
  gap: 16px;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
  pointer-events: none;
}

.ToastContextProvider_root__1sf44 {
  pointer-events: all;
}

.ToastContextProvider_root__1sf44[data-state='open'] {
  animation: ToastContextProvider_slide-in__NMrkK 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.ToastContextProvider_root__1sf44[data-state='closed'] {
  animation: ToastContextProvider_hide__yeeQG 100ms ease-in;
}

.ToastContextProvider_root__1sf44[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}

.ToastContextProvider_root__1sf44[data-swipe='cancel'] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}

.ToastContextProvider_root__1sf44[data-swipe='end'] {
  animation: ToastContextProvider_swipe-out__iwgxD 100ms ease-out;
}

@keyframes ToastContextProvider_hide__yeeQG {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes ToastContextProvider_slide-in__NMrkK {
  from {
    transform: translateX(calc(100% + var(--viewport-padding-horizontal)));
  }

  to {
    transform: translateX(0);
  }
}

@keyframes ToastContextProvider_swipe-out__iwgxD {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  to {
    transform: translateX(calc(100% + var(--viewport-padding-horizontal)));
  }
}
:where(.__wab_flex-container),
:where(.ρfc) {
  display: flex;
  flex: 1 1;
  align-self: stretch;
  pointer-events: none;
}

:where(.__wab_flex-container > *),
:where(.ρfc > *) {
  pointer-events: auto;
}

:where(.__wab_slot),
:where(.ρs) {
  display: contents;
}

:where(.__wab_slot-string-wrapper),
:where(.ρsw) {
  position: relative;
}

:where(.__wab_passthrough) {
  display: contents;
}

:where(.__wab_img-wrapper) {
  position: relative;
  display: inherit;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;
}

:where(.__wab_slot > .__wab_img-wrapper),
:where(.ρs > .__wab_img-wrapper) {
  display: block;
}

:where(.__wab_passthrough > .__wab_img-wrapper) {
  display: block;
}

:where(.__wab_img-spacer-svg) {
  display: block;
  margin: 0;
  border: none;
  padding: 0;
}

:where(.__wab_img) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  border: none;
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

:where(.__wab_picture) {
  display: contents;
}

.plasmic_plasmic_tokens__HV6qT {
  --token-9T9NC2lhv1Ej: #ffffff;
  --plasmic-token-solid-white: var(--token-9T9NC2lhv1Ej);
  --token-nZXoHFRat8AV: #000000;
  --plasmic-token-solid-black: var(--token-nZXoHFRat8AV);
  --token-ERHt8-nljaMx: #fafbfc;
  --plasmic-token-solid-gray-05: var(--token-ERHt8-nljaMx);
  --token-w1v4JEx-uq2e: #f4f6f8;
  --plasmic-token-solid-gray-10: var(--token-w1v4JEx-uq2e);
  --token-7u3Fjf0ZO4pV: #e0e5ea;
  --plasmic-token-solid-gray-20: var(--token-7u3Fjf0ZO4pV);
  --token-NagwnEgqYDVz: #c4cbd2;
  --plasmic-token-solid-gray-30: var(--token-NagwnEgqYDVz);
  --token-3hsdHs9SQrQP: #9fa6ad;
  --plasmic-token-solid-gray-40: var(--token-3hsdHs9SQrQP);
  --token-IjBUITyVGNVJ: #868c93;
  --plasmic-token-solid-gray-50: var(--token-IjBUITyVGNVJ);
  --token-sJr9sbZwbyqK: #59626b;
  --plasmic-token-solid-gray-60: var(--token-sJr9sbZwbyqK);
  --token-6bXw4oz7csc-: #414549;
  --plasmic-token-solid-gray-70: var(--token-6bXw4oz7csc-);
  --token-Uy2x1Cuw6kvC: #2e3235;
  --plasmic-token-solid-gray-80: var(--token-Uy2x1Cuw6kvC);
  --token-x0ltlSxVUZco: #1f2123;
  --plasmic-token-solid-gray-90: var(--token-x0ltlSxVUZco);
  --token-F-isEiSy-7QO: #e1f4ff;
  --plasmic-token-solid-blue-10: var(--token-F-isEiSy-7QO);
  --token-eqvPWoOG9mbc: #8bcdf3;
  --plasmic-token-solid-blue-20: var(--token-eqvPWoOG9mbc);
  --token-pkN2y4iq-viG: #087fb3;
  --plasmic-token-solid-blue-30: var(--token-pkN2y4iq-viG);
  --token-gaowRD3VsD3w: #005a8c;
  --plasmic-token-solid-blue-60: var(--token-gaowRD3VsD3w);
  --token-vF1ggEL4NG72: #003c70;
  --plasmic-token-solid-blue-80: var(--token-vF1ggEL4NG72);
  --token-f0Mcnp3uZyQy: #ffebe8;
  --plasmic-token-solid-coral-05: var(--token-f0Mcnp3uZyQy);
  --token-WnhL5TAc8eef: #ffc7be;
  --plasmic-token-solid-coral-10: var(--token-WnhL5TAc8eef);
  --token-JYEEwSwHupuU: #ff715a;
  --plasmic-token-solid-coral-20: var(--token-JYEEwSwHupuU);
  --token-pmZ4Vyx2r26Q: #d23f10;
  --plasmic-token-solid-coral-30: var(--token-pmZ4Vyx2r26Q);
  --token-M9WLcJW70NrG: #e8ffd7;
  --plasmic-token-solid-green-10: var(--token-M9WLcJW70NrG);
  --token-sfW5F3w_W8A2: #81e733;
  --plasmic-token-solid-green-20: var(--token-sfW5F3w_W8A2);
  --token-rfZ4zZkwVORO: #57b310;
  --plasmic-token-solid-green-30: var(--token-rfZ4zZkwVORO);
  --token-sx8pCYJaanUh: #f6cfaa;
  --plasmic-token-solid-accent-orange-10: var(--token-sx8pCYJaanUh);
  --token-MzxWuds4CjwR: #8e4400;
  --plasmic-token-solid-accent-orange-70: var(--token-MzxWuds4CjwR);
  --token-FAhL2Mfn3yM0: #e3bfff;
  --plasmic-token-solid-accent-violet-10: var(--token-FAhL2Mfn3yM0);
  --token-7ayPLCftgtII: #490083;
  --plasmic-token-solid-accent-violet-70: var(--token-7ayPLCftgtII);
  --token-ga-kY0Io0LEZ: #afd5fc;
  --plasmic-token-solid-accent-blue-10: var(--token-ga-kY0Io0LEZ);
  --token-xCGZz4-mn0RV: #1342e8;
  --plasmic-token-solid-accent-blue-70: var(--token-xCGZz4-mn0RV);
  --token-F1M30cPrvh3r: #a6f7f2;
  --plasmic-token-solid-accent-green-10: var(--token-F1M30cPrvh3r);
  --token-jRMwyvGZK0vW: #006761;
  --plasmic-token-solid-accent-green-70: var(--token-jRMwyvGZK0vW);
  --token-tL4K7mW8qYJH: #fea9f0;
  --plasmic-token-solid-accent-pink-10: var(--token-tL4K7mW8qYJH);
  --token-8RsmlSuL8EPL: #970376;
  --plasmic-token-solid-accent-pink-70: var(--token-8RsmlSuL8EPL);
  --token-16-dlyW8ufwh: #b4ebfa;
  --plasmic-token-solid-accent-turquoise-10: var(--token-16-dlyW8ufwh);
  --token-soMM-WfmvKHK: #1d8ba9;
  --plasmic-token-solid-accent-turquoise-70: var(--token-soMM-WfmvKHK);
  --token-6ihMHiXSX_i2: #bd3308;
  --plasmic-token-solid-coral-40: var(--token-6ihMHiXSX_i2);
  --token-5WeNl9usbH_d: #a7fffc;
  --plasmic-token-solid-primary-05: var(--token-5WeNl9usbH_d);
  --token-iqU3sAfnTefg: #5ed9d6;
  --plasmic-token-solid-primary-10: var(--token-iqU3sAfnTefg);
  --token-ZCY3F4ESGxWk: #00a7a3;
  --plasmic-token-solid-primary-20: var(--token-ZCY3F4ESGxWk);
  --token--gqevcApknJE: #008c88;
  --plasmic-token-solid-primary-30: var(--token--gqevcApknJE);
  --token-ewlegQppfo1J: rgba(0, 103, 100, 1);
  --plasmic-token-solid-primary-40: var(--token-ewlegQppfo1J);
  --token-ioced1ZkDMvE: #005856;
  --plasmic-token-solid-primary-50: var(--token-ioced1ZkDMvE);
  --token-hk2-p1B3ARYw: #004443;
  --plasmic-token-solid-primary-60: var(--token-hk2-p1B3ARYw);
  --token-_CD8D92vD3hA: #003130;
  --plasmic-token-solid-primary-70: var(--token-_CD8D92vD3hA);
  --token-WvJAmfS3B3gH: rgba(0, 34, 34, 1);
  --plasmic-token-solid-primary-80: var(--token-WvJAmfS3B3gH);
  --token-uob3a0bI0eoP: #001b1a;
  --plasmic-token-solid-primary-90: var(--token-uob3a0bI0eoP);
  --token-aKYGyL-1B7v3: #99c9fc;
  --plasmic-token-solid-secondary-05: var(--token-aKYGyL-1B7v3);
  --token-on6uSmFOpxWF: #4696e8;
  --plasmic-token-solid-secondary-10: var(--token-on6uSmFOpxWF);
  --token-PoaUmQohdS1F: #2378ce;
  --plasmic-token-solid-secondary-20: var(--token-PoaUmQohdS1F);
  --token-xQYrBFgQ3pfr: #0b61b9;
  --plasmic-token-solid-secondary-30: var(--token-xQYrBFgQ3pfr);
  --token-o3CfadBKU-1J: #004c9b;
  --plasmic-token-solid-secondary-40: var(--token-o3CfadBKU-1J);
  --token-3P4aM2WzDos2: #003f80;
  --plasmic-token-solid-secondary-50: var(--token-3P4aM2WzDos2);
  --token-LjXCP1lIAacL: #003366;
  --plasmic-token-solid-secondary-60: var(--token-LjXCP1lIAacL);
  --token-_hjyY2bFxFBI: #00274f;
  --plasmic-token-solid-secondary-70: var(--token-_hjyY2bFxFBI);
  --token-lBBcScEFC1iW: #001c38;
  --plasmic-token-solid-secondary-80: var(--token-lBBcScEFC1iW);
  --token-FyWCqlyR3lo9: #001225;
  --plasmic-token-solid-secondary-90: var(--token-FyWCqlyR3lo9);
  --token-o0-GjBbt3YK-: rgba(153, 255, 153, 1);
  --plasmic-token-solid-success-05: var(--token-o0-GjBbt3YK-);
  --token--KmAl11udt8T: rgba(74, 239, 74, 1);
  --plasmic-token-solid-success-10: var(--token--KmAl11udt8T);
  --token-GEhQLaidx1FS: #0fa80f;
  --plasmic-token-solid-success-20: var(--token-GEhQLaidx1FS);
  --token-HSjV8ipmZEwO: #007f00;
  --plasmic-token-solid-success-30: var(--token-HSjV8ipmZEwO);
  --token-GOpcrXPwUwrV: rgba(0, 103, 0, 1);
  --plasmic-token-solid-success-40: var(--token-GOpcrXPwUwrV);
  --token-vFH6HQkY_8eh: #005400;
  --plasmic-token-solid-success-50: var(--token-vFH6HQkY_8eh);
  --token-KzH4_IKzKwxZ: #004300;
  --plasmic-token-solid-success-60: var(--token-KzH4_IKzKwxZ);
  --token-3mvYaNs82Vuz: #003800;
  --plasmic-token-solid-success-70: var(--token-3mvYaNs82Vuz);
  --token-xumLagKFUsyn: rgba(0, 44, 0, 1);
  --plasmic-token-solid-success-80: var(--token-xumLagKFUsyn);
  --token-Xefbqm4rIhAz: rgba(0, 32, 0, 1);
  --plasmic-token-solid-success-90: var(--token-Xefbqm4rIhAz);
  --token-JbV9XhTSzMQw: rgba(255, 236, 136, 1);
  --plasmic-token-solid-notice-05: var(--token-JbV9XhTSzMQw);
  --token-p4v5hRDawSwW: #f5d945;
  --plasmic-token-solid-notice-10: var(--token-p4v5hRDawSwW);
  --token-HAq2NCDlt41h: #edc700;
  --plasmic-token-solid-notice-20: var(--token-HAq2NCDlt41h);
  --token-9hTQS47K3ccW: #ae9200;
  --plasmic-token-solid-notice-30: var(--token-9hTQS47K3ccW);
  --token-pUBn_oMpXTY1: rgba(130, 109, 0, 1);
  --plasmic-token-solid-notice-40: var(--token-pUBn_oMpXTY1);
  --token-iA3C3sAIPSuu: rgba(107, 90, 0, 1);
  --plasmic-token-solid-notice-50: var(--token-iA3C3sAIPSuu);
  --token-ehRnWhYgMmk5: rgba(81, 68, 0, 1);
  --plasmic-token-solid-notice-60: var(--token-ehRnWhYgMmk5);
  --token-mzyFQmlUPXj7: rgba(63, 53, 0, 1);
  --plasmic-token-solid-notice-70: var(--token-mzyFQmlUPXj7);
  --token-517q50vSE7eH: rgba(46, 39, 0, 1);
  --plasmic-token-solid-notice-80: var(--token-517q50vSE7eH);
  --token-UUqIOjeUKAXr: rgba(34, 28, 0, 1);
  --plasmic-token-solid-notice-90: var(--token-UUqIOjeUKAXr);
  --token-cq-Q2eSeG3Ce: rgba(247, 187, 131, 1);
  --plasmic-token-solid-warning-05: var(--token-cq-Q2eSeG3Ce);
  --token-1g9jaWaaNtq_: #f49945;
  --plasmic-token-solid-warning-10: var(--token-1g9jaWaaNtq_);
  --token-iIb1PKIe4dly: rgba(227, 121, 23, 1);
  --plasmic-token-solid-warning-20: var(--token-iIb1PKIe4dly);
  --token-jedE8NGXFVv6: #bb5900;
  --plasmic-token-solid-warning-30: var(--token-jedE8NGXFVv6);
  --token-lhXkBJGFrEFa: rgba(142, 67, 0, 1);
  --plasmic-token-solid-warning-40: var(--token-lhXkBJGFrEFa);
  --token-DOvxw6q7dqRc: rgba(122, 58, 0, 1);
  --plasmic-token-solid-warning-50: var(--token-DOvxw6q7dqRc);
  --token-35V46JVvA2dT: rgba(104, 49, 0, 1);
  --plasmic-token-solid-warning-60: var(--token-35V46JVvA2dT);
  --token-Na4rSAI47HaG: rgba(78, 37, 0, 1);
  --plasmic-token-solid-warning-70: var(--token-Na4rSAI47HaG);
  --token-STWWaxMPN0iq: #341800;
  --plasmic-token-solid-warning-80: var(--token-STWWaxMPN0iq);
  --token-cfpU9OyKs7hR: rgba(41, 19, 0, 1);
  --plasmic-token-solid-warning-90: var(--token-cfpU9OyKs7hR);
  --token-nhed4qAGMWMl: rgba(255, 164, 164, 1);
  --plasmic-token-solid-error-05: var(--token-nhed4qAGMWMl);
  --token-IHOmfBYA37RU: rgba(251, 106, 106, 1);
  --plasmic-token-solid-error-10: var(--token-IHOmfBYA37RU);
  --token-A5SknWmS3YfQ: #ea3232;
  --plasmic-token-solid-error-20: var(--token-A5SknWmS3YfQ);
  --token-wztJeibDmC_Z: #d10000;
  --plasmic-token-solid-error-30: var(--token-wztJeibDmC_Z);
  --token-g9ru0UDFwC4M: #aa0000;
  --plasmic-token-solid-error-40: var(--token-g9ru0UDFwC4M);
  --token-oIYnGCUAN8uS: #940000;
  --plasmic-token-solid-error-50: var(--token-oIYnGCUAN8uS);
  --token-xSdFI7PoMVNo: rgba(109, 0, 0, 1);
  --plasmic-token-solid-error-60: var(--token-xSdFI7PoMVNo);
  --token-YE0gqRfGRIR8: rgba(72, 0, 0, 1);
  --plasmic-token-solid-error-70: var(--token-YE0gqRfGRIR8);
  --token-d5TL7_X6Lviy: #340000;
  --plasmic-token-solid-error-80: var(--token-d5TL7_X6Lviy);
  --token-_HT2Eky_EGvK: #1e0000;
  --plasmic-token-solid-error-90: var(--token-_HT2Eky_EGvK);
  --token-7jALs9WK8ZVb: #42b6d5;
  --plasmic-token-solid-accent-turquoise-30: var(--token-7jALs9WK8ZVb);
  --token-8WwcVVT045jy: rgba(28, 138, 168, 1);
  --plasmic-token-solid-accent-turquoise-40: var(--token-8WwcVVT045jy);
  --token-yRzZdeR-lkVl: rgba(14, 109, 134, 1);
  --plasmic-token-solid-accent-turquoise-50: var(--token-yRzZdeR-lkVl);
  --token-EXzSd5yWEvn4: rgba(19, 65, 231, 1);
  --plasmic-token-solid-accent-navy-30: var(--token-EXzSd5yWEvn4);
  --token-AI6-sOI9SB-A: rgba(6, 47, 189, 1);
  --plasmic-token-solid-accent-navy-40: var(--token-AI6-sOI9SB-A);
  --token-iuqkuN2ViSz8: rgba(6, 41, 166, 1);
  --plasmic-token-solid-accent-navy-50: var(--token-iuqkuN2ViSz8);
  --token-tHiq6SLPN-0m: #d634f0;
  --plasmic-token-solid-accent-magenta-20: var(--token-tHiq6SLPN-0m);
  --token-_xAboZji8WZH: rgba(167, 15, 192, 1);
  --plasmic-token-solid-accent-magenta-30: var(--token-_xAboZji8WZH);
  --token-QwiOfeB6hAnC: rgba(130, 3, 150, 1);
  --plasmic-token-solid-accent-magenta-40: var(--token-QwiOfeB6hAnC);
  --token-bCLjOxICmFcL: rgba(103, 0, 119, 1);
  --plasmic-token-solid-accent-magenta-50: var(--token-bCLjOxICmFcL);
  --token-irQLn6HWxlFL: rgba(84, 0, 98, 1);
  --plasmic-token-solid-accent-magenta-60: var(--token-irQLn6HWxlFL);
  --token-CW-NSLdG88WZ: rgba(238, 238, 238, 1);
  --plasmic-token-solid-gray-05: var(--token-CW-NSLdG88WZ);
  --token-IRCruaPA7hTk: #d6d6d6;
  --plasmic-token-solid-gray-10: var(--token-IRCruaPA7hTk);
  --token-kJFgGuMSNxmW: rgba(183, 183, 183, 1);
  --plasmic-token-solid-gray-20: var(--token-kJFgGuMSNxmW);
  --token-XXsK5Dlj6dvg: #8f8f8f;
  --plasmic-token-solid-gray-30: var(--token-XXsK5Dlj6dvg);
  --token-8UPZ8w-CQsFe: rgba(117, 117, 117, 1);
  --plasmic-token-solid-gray-40: var(--token-8UPZ8w-CQsFe);
  --token-B4M3LC8ZT1uK: #545454;
  --plasmic-token-solid-gray-50: var(--token-B4M3LC8ZT1uK);
  --token-OWojUp3DNW77: rgba(54, 54, 54, 1);
  --plasmic-token-solid-gray-60: var(--token-OWojUp3DNW77);
  --token-RqdYJ6oeMFYq: #282828;
  --plasmic-token-solid-gray-70: var(--token-RqdYJ6oeMFYq);
  --token-lmwh-Kt360Pd: #1e1e1e;
  --plasmic-token-solid-gray-80: var(--token-lmwh-Kt360Pd);
  --token-ehzwoACCT0PU: #171717;
  --plasmic-token-solid-gray-90: var(--token-ehzwoACCT0PU);
}

.plasmic_plasmic_tokens__HV6qT {
  --plsmc-standard-width: 1152px;
  --plsmc-wide-width: 1152px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_plasmic_default_styles__rRJ9n {
  --mixin-MxgHGUPFzARY_font-family: var(--roboto);
  --mixin-MxgHGUPFzARY_font-size: 16px;
  --mixin-MxgHGUPFzARY_font-weight: 400;
  --mixin-MxgHGUPFzARY_font-style: normal;
  --mixin-MxgHGUPFzARY_color: var(--token-x0ltlSxVUZco);
  --mixin-MxgHGUPFzARY_text-align: left;
  --mixin-MxgHGUPFzARY_text-transform: none;
  --mixin-MxgHGUPFzARY_line-height: 24px;
  --mixin-MxgHGUPFzARY_letter-spacing: normal;
  --mixin-MxgHGUPFzARY_white-space: pre-wrap;
  --mixin-MxgHGUPFzARY_user-select: text;
  --mixin-MxgHGUPFzARY_text-decoration-line: none;
  --mixin-MxgHGUPFzARY_text-overflow: clip;
  --mixin-1bh3XlHrYt27_color: var(--token-x0ltlSxVUZco);
  --mixin-1bh3XlHrYt27_font-weight: 700;
  --mixin-1bh3XlHrYt27_font-size: 32px;
  --mixin-1bh3XlHrYt27_line-height: 48px;
  --mixin-1bh3XlHrYt27_font-family: var(--rubik);
  --mixin-1bh3XlHrYt27_white-space: pre-wrap;
  --mixin-oU3hHmE-P_LX_color: var(--token-x0ltlSxVUZco);
  --mixin-oU3hHmE-P_LX_font-size: 28px;
  --mixin-oU3hHmE-P_LX_font-weight: 700;
  --mixin-oU3hHmE-P_LX_line-height: 40px;
  --mixin-oU3hHmE-P_LX_font-family: var(--rubik);
  --mixin-oU3hHmE-P_LX_white-space: pre-wrap;
  --mixin-8TkpYEsz3Wto_color: var(--token-pkN2y4iq-viG);
  --mixin-8TkpYEsz3Wto_font-family: var(--roboto);
  --mixin-8TkpYEsz3Wto_font-size: 16px;
  --mixin-8TkpYEsz3Wto_line-height: 24px;
  --mixin-8TkpYEsz3Wto_white-space: pre-wrap;
  --mixin-iJZzRc4Lieqr_color: var(--token-x0ltlSxVUZco);
  --mixin-iJZzRc4Lieqr_font-size: 24px;
  --mixin-iJZzRc4Lieqr_font-weight: 600;
  --mixin-iJZzRc4Lieqr_line-height: 32px;
  --mixin-iJZzRc4Lieqr_font-family: var(--rubik);
  --mixin-iJZzRc4Lieqr_white-space: pre-wrap;
  --mixin-kb84McAzt0oK_color: var(--token-x0ltlSxVUZco);
  --mixin-kb84McAzt0oK_font-size: 21px;
  --mixin-kb84McAzt0oK_font-weight: 600;
  --mixin-kb84McAzt0oK_line-height: 28px;
  --mixin-kb84McAzt0oK_font-family: var(--rubik);
  --mixin-kb84McAzt0oK_white-space: pre-wrap;
  --mixin-_LA8PinxOMWK_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-_LA8PinxOMWK_border-bottom-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-bottom-style: solid;
  --mixin-_LA8PinxOMWK_border-bottom-width: 1px;
  --mixin-_LA8PinxOMWK_border-left-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-left-style: solid;
  --mixin-_LA8PinxOMWK_border-left-width: 1px;
  --mixin-_LA8PinxOMWK_border-right-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-right-style: solid;
  --mixin-_LA8PinxOMWK_border-right-width: 1px;
  --mixin-_LA8PinxOMWK_border-top-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-top-style: solid;
  --mixin-_LA8PinxOMWK_border-top-width: 1px;
  --mixin-_LA8PinxOMWK_border-bottom-left-radius: 3px;
  --mixin-_LA8PinxOMWK_border-bottom-right-radius: 3px;
  --mixin-_LA8PinxOMWK_border-top-left-radius: 3px;
  --mixin-_LA8PinxOMWK_border-top-right-radius: 3px;
  --mixin-_LA8PinxOMWK_padding-bottom: 1px;
  --mixin-_LA8PinxOMWK_padding-left: 4px;
  --mixin-_LA8PinxOMWK_padding-right: 4px;
  --mixin-_LA8PinxOMWK_padding-top: 1px;
  --mixin-_LA8PinxOMWK_white-space: pre-wrap;
  --mixin-1zUr_KBU36Gb_border-left-color: #dddddd;
  --mixin-1zUr_KBU36Gb_border-left-style: solid;
  --mixin-1zUr_KBU36Gb_border-left-width: 3px;
  --mixin-1zUr_KBU36Gb_color: #888888;
  --mixin-1zUr_KBU36Gb_padding-left: 10px;
  --mixin-1zUr_KBU36Gb_white-space: pre-wrap;
  --mixin-Tzi7GzKBiu8__background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Tzi7GzKBiu8__border-bottom-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-bottom-style: solid;
  --mixin-Tzi7GzKBiu8__border-bottom-width: 1px;
  --mixin-Tzi7GzKBiu8__border-left-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-left-style: solid;
  --mixin-Tzi7GzKBiu8__border-left-width: 1px;
  --mixin-Tzi7GzKBiu8__border-right-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-right-style: solid;
  --mixin-Tzi7GzKBiu8__border-right-width: 1px;
  --mixin-Tzi7GzKBiu8__border-top-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-top-style: solid;
  --mixin-Tzi7GzKBiu8__border-top-width: 1px;
  --mixin-Tzi7GzKBiu8__border-bottom-left-radius: 3px;
  --mixin-Tzi7GzKBiu8__border-bottom-right-radius: 3px;
  --mixin-Tzi7GzKBiu8__border-top-left-radius: 3px;
  --mixin-Tzi7GzKBiu8__border-top-right-radius: 3px;
  --mixin-Tzi7GzKBiu8__padding-bottom: 3px;
  --mixin-Tzi7GzKBiu8__padding-left: 6px;
  --mixin-Tzi7GzKBiu8__padding-right: 6px;
  --mixin-Tzi7GzKBiu8__padding-top: 3px;
  --mixin-Tzi7GzKBiu8__white-space: pre-wrap;
  --mixin-KV5A5I_vAiFD_display: flex;
  --mixin-KV5A5I_vAiFD_flex-direction: column;
  --mixin-KV5A5I_vAiFD_align-items: stretch;
  --mixin-KV5A5I_vAiFD_justify-content: flex-start;
  --mixin-KV5A5I_vAiFD_list-style-position: outside;
  --mixin-KV5A5I_vAiFD_padding-left: 40px;
  --mixin-KV5A5I_vAiFD_position: relative;
  --mixin-KV5A5I_vAiFD_list-style-type: disc;
  --mixin-KV5A5I_vAiFD_white-space: pre-wrap;
  --mixin-YqYYrXDnWKMj_display: flex;
  --mixin-YqYYrXDnWKMj_flex-direction: column;
  --mixin-YqYYrXDnWKMj_align-items: stretch;
  --mixin-YqYYrXDnWKMj_justify-content: flex-start;
  --mixin-YqYYrXDnWKMj_list-style-position: outside;
  --mixin-YqYYrXDnWKMj_padding-left: 40px;
  --mixin-YqYYrXDnWKMj_position: relative;
  --mixin-YqYYrXDnWKMj_list-style-type: decimal;
  --mixin-YqYYrXDnWKMj_white-space: pre-wrap;
  --mixin-PEKZ-DU2gF79_color: #000000;
  --mixin-PEKZ-DU2gF79_font-size: 18px;
  --mixin-PEKZ-DU2gF79_font-weight: 600;
  --mixin-PEKZ-DU2gF79_line-height: 24px;
  --mixin-PEKZ-DU2gF79_font-family: var(--rubik);
  --mixin-PEKZ-DU2gF79_white-space: pre-wrap;
  --mixin-YflEUQ8pP7Vs_color: var(--token-x0ltlSxVUZco);
  --mixin-YflEUQ8pP7Vs_font-size: 16px;
  --mixin-YflEUQ8pP7Vs_font-weight: 600;
  --mixin-YflEUQ8pP7Vs_line-height: 24px;
  --mixin-YflEUQ8pP7Vs_font-family: var(--rubik);
  --mixin-YflEUQ8pP7Vs_white-space: pre-wrap;
  --mixin-B5WnqqW-xgKz_color: var(--token-gaowRD3VsD3w);
  --mixin-B5WnqqW-xgKz_white-space: pre-wrap;
  --mixin-zb0yD342SDja_white-space: pre-wrap;
  --mixin-K-TsDPINB79u_font-family: var(--roboto);
  --mixin-K-TsDPINB79u_color: var(--token-x0ltlSxVUZco);
  --mixin-K-TsDPINB79u_font-weight: 500;
  --mixin-K-TsDPINB79u_font-size: 10px;
  --mixin-K-TsDPINB79u_line-height: 12px;
  --mixin-K-TsDPINB79u_white-space: pre-wrap;
  --mixin-_sJ3mZm2Lxao_white-space: pre-wrap;
  --mixin-VGbZIZxq7-kl_white-space: pre-wrap;
  --mixin-fTLgGuWrHW4I_color: var(--token-gaowRD3VsD3w);
  --mixin-fTLgGuWrHW4I_white-space: pre-wrap;
}

.plasmic_plasmic_mixins__k9pnZ {
  --mixin-ejS-snTcbmmH_white-space: pre-wrap;
  --plasmic-mixin-text-heading-display_white-space: var(
    --mixin-ejS-snTcbmmH_white-space
  );
  --mixin-1SFMDTzfOhd5_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-1_white-space: var(
    --mixin-1SFMDTzfOhd5_white-space
  );
  --mixin-zyrR-poZAR_c_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-2_white-space: var(
    --mixin-zyrR-poZAR_c_white-space
  );
  --mixin-FGv7O3YiNUFK_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-3_white-space: var(
    --mixin-FGv7O3YiNUFK_white-space
  );
  --mixin-qCq7FskmBXbb_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-4_white-space: var(
    --mixin-qCq7FskmBXbb_white-space
  );
  --mixin-6iYzHAY1M8KJ_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-5_white-space: var(
    --mixin-6iYzHAY1M8KJ_white-space
  );
  --mixin-oET7Miu-9uPm_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-6_white-space: var(
    --mixin-oET7Miu-9uPm_white-space
  );
  --mixin-S4M51aB-IP-Z_white-space: pre-wrap;
  --plasmic-mixin-text-heading-medium_white-space: var(
    --mixin-S4M51aB-IP-Z_white-space
  );
  --mixin-6n5RWR1WNhEr_white-space: pre-wrap;
  --plasmic-mixin-text-heading-small_white-space: var(
    --mixin-6n5RWR1WNhEr_white-space
  );
  --mixin-f6cJEXVuwxPY_white-space: pre-wrap;
  --plasmic-mixin-text-caps-large_white-space: var(
    --mixin-f6cJEXVuwxPY_white-space
  );
  --mixin-op8XvD2NVqnx_white-space: pre-wrap;
  --plasmic-mixin-text-caps-medium_white-space: var(
    --mixin-op8XvD2NVqnx_white-space
  );
  --mixin-g8Cc3dg4jOUv_white-space: pre-wrap;
  --plasmic-mixin-text-caps-small_white-space: var(
    --mixin-g8Cc3dg4jOUv_white-space
  );
  --mixin-LtzLIAjb6hvV_white-space: pre-wrap;
  --plasmic-mixin-text-link-default_white-space: var(
    --mixin-LtzLIAjb6hvV_white-space
  );
  --mixin-2HSXNLyUf7Qs_white-space: pre-wrap;
  --plasmic-mixin-text-link-active_white-space: var(
    --mixin-2HSXNLyUf7Qs_white-space
  );
  --mixin-BNRz1JHb5MoX_white-space: pre-wrap;
  --plasmic-mixin-text-body-lead_white-space: var(
    --mixin-BNRz1JHb5MoX_white-space
  );
  --mixin-Fp22zdN7YJip_white-space: pre-wrap;
  --plasmic-mixin-text-body-large_white-space: var(
    --mixin-Fp22zdN7YJip_white-space
  );
  --mixin-q6jvu0YM_jrD_white-space: pre-wrap;
  --plasmic-mixin-text-body-medium_white-space: var(
    --mixin-q6jvu0YM_jrD_white-space
  );
  --mixin-iR9Mv5PY1rkg_white-space: pre-wrap;
  --plasmic-mixin-text-body-small_white-space: var(
    --mixin-iR9Mv5PY1rkg_white-space
  );
  --mixin-1i1gL_Top87R_box-shadow: inset 0px 1px 4px 2px #0f2a410d;
  --plasmic-mixin-shadow-in-soft_box-shadow: var(
    --mixin-1i1gL_Top87R_box-shadow
  );
  --mixin-1i1gL_Top87R_white-space: pre-wrap;
  --plasmic-mixin-shadow-in-soft_white-space: var(
    --mixin-1i1gL_Top87R_white-space
  );
  --mixin-TlHf5y-0_fAR_box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  --plasmic-mixin-shadow-in-medium_box-shadow: var(
    --mixin-TlHf5y-0_fAR_box-shadow
  );
  --mixin-TlHf5y-0_fAR_white-space: pre-wrap;
  --plasmic-mixin-shadow-in-medium_white-space: var(
    --mixin-TlHf5y-0_fAR_white-space
  );
  --mixin-uczu7e64seZe_box-shadow: inset 0px 1px 4px 2px #0f2a4133;
  --plasmic-mixin-shadow-in-strong_box-shadow: var(
    --mixin-uczu7e64seZe_box-shadow
  );
  --mixin-uczu7e64seZe_white-space: pre-wrap;
  --plasmic-mixin-shadow-in-strong_white-space: var(
    --mixin-uczu7e64seZe_white-space
  );
  --mixin-SjV8dYFe6zND_box-shadow: 0px 2px 8px 0px #0f2a410f,
    0px 1px 2px -1px #0f2a410a;
  --plasmic-mixin-shadow-out-soft_box-shadow: var(
    --mixin-SjV8dYFe6zND_box-shadow
  );
  --mixin-SjV8dYFe6zND_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-soft_white-space: var(
    --mixin-SjV8dYFe6zND_white-space
  );
  --mixin-3VEN6HfO77MD_box-shadow: 0px 2px 12px -2px #0f2a411a,
    0px 1px 4px -2px #0f2a410f;
  --plasmic-mixin-shadow-out-medium_box-shadow: var(
    --mixin-3VEN6HfO77MD_box-shadow
  );
  --mixin-3VEN6HfO77MD_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-medium_white-space: var(
    --mixin-3VEN6HfO77MD_white-space
  );
  --mixin-D1H_d57eKN2E_box-shadow: 0px 4px 20px -4px #0f2a4126,
    0px 2px 6px -3px #0f2a411a;
  --plasmic-mixin-shadow-out-strong_box-shadow: var(
    --mixin-D1H_d57eKN2E_box-shadow
  );
  --mixin-D1H_d57eKN2E_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-strong_white-space: var(
    --mixin-D1H_d57eKN2E_white-space
  );
  --mixin-u8BHzGi8FDHG_box-shadow: 0px 4px 32px -4px #0f2a4133,
    0px 2px 8px -4px #0f2a4133;
  --plasmic-mixin-shadow-out-x-strong_box-shadow: var(
    --mixin-u8BHzGi8FDHG_box-shadow
  );
  --mixin-u8BHzGi8FDHG_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-x-strong_white-space: var(
    --mixin-u8BHzGi8FDHG_white-space
  );
  --mixin-vw1hWUQq-b6q_white-space: pre-wrap;
  --plasmic-mixin-meta-subtitle_white-space: var(
    --mixin-vw1hWUQq-b6q_white-space
  );
  --mixin-I37VtYFn1Nth_white-space: pre-wrap;
  --plasmic-mixin-pattern-light-gray_white-space: var(
    --mixin-I37VtYFn1Nth_white-space
  );
  --mixin-l1PL3lWAWa4z_white-space: pre-wrap;
  --plasmic-mixin-gradient-blue-linear-10_white-space: var(
    --mixin-l1PL3lWAWa4z_white-space
  );
  --mixin-x9Zmd4XfsOhN_white-space: pre-wrap;
  --plasmic-mixin-gradient-blue-linear-30_white-space: var(
    --mixin-x9Zmd4XfsOhN_white-space
  );
  --mixin-BgZ72nf0Q95u_white-space: pre-wrap;
  --plasmic-mixin-text-link-hover_white-space: var(
    --mixin-BgZ72nf0Q95u_white-space
  );
  --mixin-gpSh2J4DL4gt_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-h-1_white-space: var(
    --mixin-gpSh2J4DL4gt_white-space
  );
  --mixin-u3qvt-DaSSFO_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-h-5_white-space: var(
    --mixin-u3qvt-DaSSFO_white-space
  );
  --mixin-1Ex1aJf_jLI-_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-h-6_white-space: var(
    --mixin-1Ex1aJf_jLI-_white-space
  );
  --mixin-21eCexhoWSac_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-medium_white-space: var(
    --mixin-21eCexhoWSac_white-space
  );
  --mixin-66x0oN99QXly_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-body-large_white-space: var(
    --mixin-66x0oN99QXly_white-space
  );
  --mixin-RfHrxQ3jgh0O_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-body-medium_white-space: var(
    --mixin-RfHrxQ3jgh0O_white-space
  );
  --mixin-u8QNt7vO8g3d_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-body-small_white-space: var(
    --mixin-u8QNt7vO8g3d_white-space
  );
  --mixin-Hrt0qE7P6LgD_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-caps-medium_white-space: var(
    --mixin-Hrt0qE7P6LgD_white-space
  );
  --mixin-EFd94sJVKfni_box-shadow: 0px 32px 192px -128px #0000001a,
    0px 48px 128px -64px #32415d1a;
  --plasmic-mixin-shadow-out-xx-strong_box-shadow: var(
    --mixin-EFd94sJVKfni_box-shadow
  );
  --mixin-EFd94sJVKfni_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-xx-strong_white-space: var(
    --mixin-EFd94sJVKfni_white-space
  );
  --mixin-A5Agq1fjfvUr_white-space: pre-wrap;
  --plasmic-mixin-gradient-light-gray_white-space: var(
    --mixin-A5Agq1fjfvUr_white-space
  );
}

:where(.plasmic_all__NAIaM) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic___wab_expr_html_text__yYexh *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_img__Lc55C) {
  display: inline-block;
}
:where(.plasmic___wab_expr_html_text__yYexh img) {
  white-space: inherit;
}

:where(.plasmic_li__ylx76) {
  display: list-item;
}
:where(.plasmic___wab_expr_html_text__yYexh li) {
  white-space: inherit;
}

:where(.plasmic_span__F3ryd) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_input__5fFQX) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic___wab_expr_html_text__yYexh input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_textarea__ti21P) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic___wab_expr_html_text__yYexh textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_button__5yd25) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic___wab_expr_html_text__yYexh button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_code___vhDM) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_pre__JZHwu) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_p__XoDdm) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_h1__hPT76) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h2__XePaS) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h3__0abAg) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h4__qwvJf) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h5__RvmC8) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h6__1cp9n) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_address__V14uP) {
  font-style: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_a__ygr3Y) {
  color: inherit;
}
:where(.plasmic___wab_expr_html_text__yYexh a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_ol__PJauh) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic___wab_expr_html_text__yYexh ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_ul__J6RAQ) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic___wab_expr_html_text__yYexh ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_select__He0UV) {
  padding: 2px 6px;
}
:where(.plasmic___wab_expr_html_text__yYexh select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_plasmic_default__component_wrapper__iEg_9 {
  display: grid;
}
.plasmic_plasmic_default__inline__nv_zd {
  display: inline;
}
.plasmic_plasmic_page_wrapper__jNvjP {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_plasmic_page_wrapper__jNvjP > * {
  height: auto !important;
}
.plasmic___wab_expr_html_text__yYexh {
  white-space: normal;
}
:where(.plasmic_root_reset__51f4d) {
  font-family: var(--mixin-MxgHGUPFzARY_font-family);
  font-size: var(--mixin-MxgHGUPFzARY_font-size);
  font-weight: var(--mixin-MxgHGUPFzARY_font-weight);
  font-style: var(--mixin-MxgHGUPFzARY_font-style);
  color: var(--mixin-MxgHGUPFzARY_color);
  text-align: var(--mixin-MxgHGUPFzARY_text-align);
  text-transform: var(--mixin-MxgHGUPFzARY_text-transform);
  line-height: var(--mixin-MxgHGUPFzARY_line-height);
  letter-spacing: var(--mixin-MxgHGUPFzARY_letter-spacing);
  white-space: var(--mixin-MxgHGUPFzARY_white-space);
}

:where(.plasmic_root_reset__51f4d) h1:where(.plasmic_h1__hPT76),
h1:where(.plasmic_root_reset__51f4d.plasmic_h1__hPT76),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h1,
:where(.plasmic_root_reset_tags__NQTO_) h1,
h1:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-1bh3XlHrYt27_color);
  font-weight: var(--mixin-1bh3XlHrYt27_font-weight);
  font-size: var(--mixin-1bh3XlHrYt27_font-size);
  line-height: var(--mixin-1bh3XlHrYt27_line-height);
  font-family: var(--mixin-1bh3XlHrYt27_font-family);
}

:where(.plasmic_root_reset__51f4d) h2:where(.plasmic_h2__XePaS),
h2:where(.plasmic_root_reset__51f4d.plasmic_h2__XePaS),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h2,
:where(.plasmic_root_reset_tags__NQTO_) h2,
h2:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-oU3hHmE-P_LX_color);
  font-size: var(--mixin-oU3hHmE-P_LX_font-size);
  font-weight: var(--mixin-oU3hHmE-P_LX_font-weight);
  line-height: var(--mixin-oU3hHmE-P_LX_line-height);
  font-family: var(--mixin-oU3hHmE-P_LX_font-family);
}

:where(.plasmic_root_reset__51f4d) a:where(.plasmic_a__ygr3Y),
a:where(.plasmic_root_reset__51f4d.plasmic_a__ygr3Y),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) a,
:where(.plasmic_root_reset_tags__NQTO_) a,
a:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-8TkpYEsz3Wto_color);
  font-family: var(--mixin-8TkpYEsz3Wto_font-family);
  font-size: var(--mixin-8TkpYEsz3Wto_font-size);
  line-height: var(--mixin-8TkpYEsz3Wto_line-height);
}

:where(.plasmic_root_reset__51f4d) h3:where(.plasmic_h3__0abAg),
h3:where(.plasmic_root_reset__51f4d.plasmic_h3__0abAg),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h3,
:where(.plasmic_root_reset_tags__NQTO_) h3,
h3:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-iJZzRc4Lieqr_color);
  font-size: var(--mixin-iJZzRc4Lieqr_font-size);
  font-weight: var(--mixin-iJZzRc4Lieqr_font-weight);
  line-height: var(--mixin-iJZzRc4Lieqr_line-height);
  font-family: var(--mixin-iJZzRc4Lieqr_font-family);
}

:where(.plasmic_root_reset__51f4d) h4:where(.plasmic_h4__qwvJf),
h4:where(.plasmic_root_reset__51f4d.plasmic_h4__qwvJf),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h4,
:where(.plasmic_root_reset_tags__NQTO_) h4,
h4:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-kb84McAzt0oK_color);
  font-size: var(--mixin-kb84McAzt0oK_font-size);
  font-weight: var(--mixin-kb84McAzt0oK_font-weight);
  line-height: var(--mixin-kb84McAzt0oK_line-height);
  font-family: var(--mixin-kb84McAzt0oK_font-family);
}

:where(.plasmic_root_reset__51f4d) code:where(.plasmic_code___vhDM),
code:where(.plasmic_root_reset__51f4d.plasmic_code___vhDM),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) code,
:where(.plasmic_root_reset_tags__NQTO_) code,
code:where(.plasmic_root_reset_tags__NQTO_) {
  background: #f8f8f8;
  border-radius: var(--mixin-_LA8PinxOMWK_border-top-left-radius)
    var(--mixin-_LA8PinxOMWK_border-top-right-radius)
    var(--mixin-_LA8PinxOMWK_border-bottom-right-radius)
    var(--mixin-_LA8PinxOMWK_border-bottom-left-radius);
  padding: var(--mixin-_LA8PinxOMWK_padding-top)
    var(--mixin-_LA8PinxOMWK_padding-right)
    var(--mixin-_LA8PinxOMWK_padding-bottom)
    var(--mixin-_LA8PinxOMWK_padding-left);
  border-top: var(--mixin-_LA8PinxOMWK_border-top-width)
    var(--mixin-_LA8PinxOMWK_border-top-style)
    var(--mixin-_LA8PinxOMWK_border-top-color);
  border-right: var(--mixin-_LA8PinxOMWK_border-right-width)
    var(--mixin-_LA8PinxOMWK_border-right-style)
    var(--mixin-_LA8PinxOMWK_border-right-color);
  border-bottom: var(--mixin-_LA8PinxOMWK_border-bottom-width)
    var(--mixin-_LA8PinxOMWK_border-bottom-style)
    var(--mixin-_LA8PinxOMWK_border-bottom-color);
  border-left: var(--mixin-_LA8PinxOMWK_border-left-width)
    var(--mixin-_LA8PinxOMWK_border-left-style)
    var(--mixin-_LA8PinxOMWK_border-left-color);
}

:where(.plasmic_root_reset__51f4d) blockquote:where(.plasmic_blockquote__Ms9Lk),
blockquote:where(.plasmic_root_reset__51f4d.plasmic_blockquote__Ms9Lk),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) blockquote,
:where(.plasmic_root_reset_tags__NQTO_) blockquote,
blockquote:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-1zUr_KBU36Gb_color);
  padding-left: var(--mixin-1zUr_KBU36Gb_padding-left);
  border-left: var(--mixin-1zUr_KBU36Gb_border-left-width)
    var(--mixin-1zUr_KBU36Gb_border-left-style)
    var(--mixin-1zUr_KBU36Gb_border-left-color);
}

:where(.plasmic_root_reset__51f4d) pre:where(.plasmic_pre__JZHwu),
pre:where(.plasmic_root_reset__51f4d.plasmic_pre__JZHwu),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) pre,
:where(.plasmic_root_reset_tags__NQTO_) pre,
pre:where(.plasmic_root_reset_tags__NQTO_) {
  background: #f8f8f8;
  border-radius: var(--mixin-Tzi7GzKBiu8__border-top-left-radius)
    var(--mixin-Tzi7GzKBiu8__border-top-right-radius)
    var(--mixin-Tzi7GzKBiu8__border-bottom-right-radius)
    var(--mixin-Tzi7GzKBiu8__border-bottom-left-radius);
  padding: var(--mixin-Tzi7GzKBiu8__padding-top)
    var(--mixin-Tzi7GzKBiu8__padding-right)
    var(--mixin-Tzi7GzKBiu8__padding-bottom)
    var(--mixin-Tzi7GzKBiu8__padding-left);
  border-top: var(--mixin-Tzi7GzKBiu8__border-top-width)
    var(--mixin-Tzi7GzKBiu8__border-top-style)
    var(--mixin-Tzi7GzKBiu8__border-top-color);
  border-right: var(--mixin-Tzi7GzKBiu8__border-right-width)
    var(--mixin-Tzi7GzKBiu8__border-right-style)
    var(--mixin-Tzi7GzKBiu8__border-right-color);
  border-bottom: var(--mixin-Tzi7GzKBiu8__border-bottom-width)
    var(--mixin-Tzi7GzKBiu8__border-bottom-style)
    var(--mixin-Tzi7GzKBiu8__border-bottom-color);
  border-left: var(--mixin-Tzi7GzKBiu8__border-left-width)
    var(--mixin-Tzi7GzKBiu8__border-left-style)
    var(--mixin-Tzi7GzKBiu8__border-left-color);
}

:where(.plasmic_root_reset__51f4d) ul:where(.plasmic_ul__J6RAQ),
ul:where(.plasmic_root_reset__51f4d.plasmic_ul__J6RAQ),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) ul,
:where(.plasmic_root_reset_tags__NQTO_) ul,
ul:where(.plasmic_root_reset_tags__NQTO_) {
  display: var(--mixin-KV5A5I_vAiFD_display);
  flex-direction: var(--mixin-KV5A5I_vAiFD_flex-direction);
  align-items: var(--mixin-KV5A5I_vAiFD_align-items);
  justify-content: var(--mixin-KV5A5I_vAiFD_justify-content);
  list-style-position: var(--mixin-KV5A5I_vAiFD_list-style-position);
  padding-left: var(--mixin-KV5A5I_vAiFD_padding-left);
  position: var(--mixin-KV5A5I_vAiFD_position);
  list-style-type: var(--mixin-KV5A5I_vAiFD_list-style-type);
  flex-column-gap: var(--mixin-KV5A5I_vAiFD_flex-column-gap);
}

:where(.plasmic_root_reset__51f4d) ol:where(.plasmic_ol__PJauh),
ol:where(.plasmic_root_reset__51f4d.plasmic_ol__PJauh),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) ol,
:where(.plasmic_root_reset_tags__NQTO_) ol,
ol:where(.plasmic_root_reset_tags__NQTO_) {
  display: var(--mixin-YqYYrXDnWKMj_display);
  flex-direction: var(--mixin-YqYYrXDnWKMj_flex-direction);
  align-items: var(--mixin-YqYYrXDnWKMj_align-items);
  justify-content: var(--mixin-YqYYrXDnWKMj_justify-content);
  list-style-position: var(--mixin-YqYYrXDnWKMj_list-style-position);
  padding-left: var(--mixin-YqYYrXDnWKMj_padding-left);
  position: var(--mixin-YqYYrXDnWKMj_position);
  list-style-type: var(--mixin-YqYYrXDnWKMj_list-style-type);
  flex-column-gap: var(--mixin-YqYYrXDnWKMj_flex-column-gap);
}

:where(.plasmic_root_reset__51f4d) h5:where(.plasmic_h5__RvmC8),
h5:where(.plasmic_root_reset__51f4d.plasmic_h5__RvmC8),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h5,
:where(.plasmic_root_reset_tags__NQTO_) h5,
h5:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-PEKZ-DU2gF79_color);
  font-size: var(--mixin-PEKZ-DU2gF79_font-size);
  font-weight: var(--mixin-PEKZ-DU2gF79_font-weight);
  line-height: var(--mixin-PEKZ-DU2gF79_line-height);
  font-family: var(--mixin-PEKZ-DU2gF79_font-family);
}

:where(.plasmic_root_reset__51f4d) h6:where(.plasmic_h6__1cp9n),
h6:where(.plasmic_root_reset__51f4d.plasmic_h6__1cp9n),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h6,
:where(.plasmic_root_reset_tags__NQTO_) h6,
h6:where(.plasmic_root_reset_tags__NQTO_) {
  color: var(--mixin-YflEUQ8pP7Vs_color);
  font-size: var(--mixin-YflEUQ8pP7Vs_font-size);
  font-weight: var(--mixin-YflEUQ8pP7Vs_font-weight);
  line-height: var(--mixin-YflEUQ8pP7Vs_line-height);
  font-family: var(--mixin-YflEUQ8pP7Vs_font-family);
}

:where(.plasmic_root_reset__51f4d) a:where(.plasmic_a__ygr3Y):hover,
a:where(.plasmic_root_reset__51f4d.plasmic_a__ygr3Y):hover,
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) a:hover,
:where(.plasmic_root_reset_tags__NQTO_) a:hover,
a:where(.plasmic_root_reset_tags__NQTO_):hover {
  color: var(--mixin-B5WnqqW-xgKz_color);
}

:where(.plasmic_root_reset__51f4d) li:where(.plasmic_li__ylx76),
li:where(.plasmic_root_reset__51f4d.plasmic_li__ylx76),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) li,
:where(.plasmic_root_reset_tags__NQTO_) li,
li:where(.plasmic_root_reset_tags__NQTO_) {
}

:where(.plasmic_root_reset__51f4d) p:where(.plasmic_p__XoDdm),
p:where(.plasmic_root_reset__51f4d.plasmic_p__XoDdm),
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) p,
:where(.plasmic_root_reset_tags__NQTO_) p,
p:where(.plasmic_root_reset_tags__NQTO_) {
  font-family: var(--mixin-K-TsDPINB79u_font-family);
  color: var(--mixin-K-TsDPINB79u_color);
  font-weight: var(--mixin-K-TsDPINB79u_font-weight);
  font-size: var(--mixin-K-TsDPINB79u_font-size);
  line-height: var(--mixin-K-TsDPINB79u_line-height);
}

:where(.plasmic_root_reset__51f4d) h2:where(.plasmic_h2__XePaS):hover,
h2:where(.plasmic_root_reset__51f4d.plasmic_h2__XePaS):hover,
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h2:hover,
:where(.plasmic_root_reset_tags__NQTO_) h2:hover,
h2:where(.plasmic_root_reset_tags__NQTO_):hover {
}

:where(.plasmic_root_reset__51f4d) h4:where(.plasmic_h4__qwvJf):hover,
h4:where(.plasmic_root_reset__51f4d.plasmic_h4__qwvJf):hover,
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) h4:hover,
:where(.plasmic_root_reset_tags__NQTO_) h4:hover,
h4:where(.plasmic_root_reset_tags__NQTO_):hover {
}

:where(.plasmic_root_reset__51f4d) a:where(.plasmic_a__ygr3Y):active,
a:where(.plasmic_root_reset__51f4d.plasmic_a__ygr3Y):active,
:where(.plasmic_root_reset__51f4d .plasmic___wab_expr_html_text__yYexh) a:active,
:where(.plasmic_root_reset_tags__NQTO_) a:active,
a:where(.plasmic_root_reset_tags__NQTO_):active {
  color: var(--mixin-fTLgGuWrHW4I_color);
}

.PlasmicToast_toast__rNk0o {
  box-shadow: 0px 4px 20px -4px #0f2a4126, 0px 2px 6px -3px #0f2a411a;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-9T9NC2lhv1Ej);
  height: auto;
  justify-self: flex-start;
  max-width: 350px;
  border-radius: 8px;
}
.PlasmicToast_wrapper__N6Yqm {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  align-self: flex-start;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.PlasmicToast_accent__NsNLh {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  background: var(--token-F-isEiSy-7QO);
  flex-grow: 0;
  flex-shrink: 1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px;
}
.PlasmicToast_accent__NsNLh > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.PlasmicToast_accent__NsNLh > .__wab_flex-container > *,
.PlasmicToast_accent__NsNLh > .__wab_flex-container > .__wab_slot > *,
.PlasmicToast_accent__NsNLh > .__wab_flex-container > picture > img,
.PlasmicToast_accent__NsNLh
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 8px;
}
.PlasmicToast_accenttype_error__ap4_r {
  background: var(--token-WnhL5TAc8eef);
}
.PlasmicToast_accenttype_success__Dd1MD {
  background: var(--token-M9WLcJW70NrG);
}
.PlasmicToast_accentIcon___AVU_ {
  object-fit: cover;
  max-width: 100%;
  color: #087fb3;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.PlasmicToast_accentIcontype_error__pPfD1 {
  color: var(--token-pmZ4Vyx2r26Q);
}
.PlasmicToast_accentIcontype_success__zwYXT {
  color: var(--token-rfZ4zZkwVORO);
}
.PlasmicToast_text__RWRmL {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 8px 12px;
}
.PlasmicToast_text__RWRmL > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.PlasmicToast_text__RWRmL > .__wab_flex-container > *,
.PlasmicToast_text__RWRmL > .__wab_flex-container > .__wab_slot > *,
.PlasmicToast_text__RWRmL > .__wab_flex-container > picture > img,
.PlasmicToast_text__RWRmL > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 4px;
}
.PlasmicToast_text___1U6ZH__8r9a9 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-nZXoHFRat8AV);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PlasmicToast_text__c2FXg__5oSH6 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-sJr9sbZwbyqK);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PlasmicToast_close__e4YUt {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  align-self: flex-start;
  padding: 12px;
  border-width: 0px;
}
.PlasmicToast_closeIcon__UzK7a {
  object-fit: cover;
  max-width: 100%;
  color: #59626b;
  width: 14px;
  height: 14px;
  cursor: pointer;
  flex-shrink: 0;
}

/* arabic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/m/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/m/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/m/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/m/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/m/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/m/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/m/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/m/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/m/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/m/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/m/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/m/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/m/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/m/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/m/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/m/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/m/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/m/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/m/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/m/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/m/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/m/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/m/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/m/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/m/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/m/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/m/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/m/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/m/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_6f9145';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/m/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Rubik_Fallback_6f9145';src: local("Arial");ascent-override: 89.06%;descent-override: 23.81%;line-gap-override: 0.00%;size-adjust: 104.98%
}.__className_6f9145 {font-family: '__Rubik_6f9145', '__Rubik_Fallback_6f9145';font-style: normal
}.__variable_6f9145 {--rubik: '__Rubik_6f9145', '__Rubik_Fallback_6f9145'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_1cd78b';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/m/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_1cd78b';src: local("Arial");ascent-override: 92.98%;descent-override: 24.47%;line-gap-override: 0.00%;size-adjust: 99.78%
}.__className_1cd78b {font-family: '__Roboto_1cd78b', '__Roboto_Fallback_1cd78b';font-style: normal
}.__variable_1cd78b {--roboto: '__Roboto_1cd78b', '__Roboto_Fallback_1cd78b'
}

