* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

.myevals-react-frontend__placeholder {
  position: relative;
  background-color: rgb(0 0 0 / 20%);
  overflow: hidden;
}

.myevals-react-frontend__placeholder::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    90deg,
    rgb(0 0 0 / 0%) 20%,
    rgb(0 0 0 / 10%) 40%,
    rgb(0 0 0 / 0%) 60%
  );
  animation: myevals-react-frontend__placeholder__gradient 1.5s infinite linear;
}

@keyframes myevals-react-frontend__placeholder__gradient {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(150%);
  }
}

.myevals-react-frontend__spinner {
  animation: myevals-react-frontend__spinner-animation 1s infinite linear;
}

@keyframes myevals-react-frontend__spinner-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}