.toast {
  box-shadow: 0px 4px 20px -4px #0f2a4126, 0px 2px 6px -3px #0f2a411a;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-9T9NC2lhv1Ej);
  height: auto;
  justify-self: flex-start;
  max-width: 350px;
  border-radius: 8px;
}
.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  align-self: flex-start;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.accent {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  background: var(--token-F-isEiSy-7QO);
  flex-grow: 0;
  flex-shrink: 1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px;
}
.accent > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.accent > :global(.__wab_flex-container) > *,
.accent > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.accent > :global(.__wab_flex-container) > picture > img,
.accent
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.accenttype_error {
  background: var(--token-WnhL5TAc8eef);
}
.accenttype_success {
  background: var(--token-M9WLcJW70NrG);
}
.accentIcon {
  object-fit: cover;
  max-width: 100%;
  color: #087fb3;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.accentIcontype_error {
  color: var(--token-pmZ4Vyx2r26Q);
}
.accentIcontype_success {
  color: var(--token-rfZ4zZkwVORO);
}
.text {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 8px 12px;
}
.text > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.text > :global(.__wab_flex-container) > *,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text > :global(.__wab_flex-container) > picture > img,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 4px;
}
.text___1U6ZH {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-nZXoHFRat8AV);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__c2FXg {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-sJr9sbZwbyqK);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.close {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  align-self: flex-start;
  padding: 12px;
  border-width: 0px;
}
.closeIcon {
  object-fit: cover;
  max-width: 100%;
  color: #59626b;
  width: 14px;
  height: 14px;
  cursor: pointer;
  flex-shrink: 0;
}
