.plasmic_tokens {
  --token-9T9NC2lhv1Ej: #ffffff;
  --plasmic-token-solid-white: var(--token-9T9NC2lhv1Ej);
  --token-nZXoHFRat8AV: #000000;
  --plasmic-token-solid-black: var(--token-nZXoHFRat8AV);
  --token-ERHt8-nljaMx: #fafbfc;
  --plasmic-token-solid-gray-05: var(--token-ERHt8-nljaMx);
  --token-w1v4JEx-uq2e: #f4f6f8;
  --plasmic-token-solid-gray-10: var(--token-w1v4JEx-uq2e);
  --token-7u3Fjf0ZO4pV: #e0e5ea;
  --plasmic-token-solid-gray-20: var(--token-7u3Fjf0ZO4pV);
  --token-NagwnEgqYDVz: #c4cbd2;
  --plasmic-token-solid-gray-30: var(--token-NagwnEgqYDVz);
  --token-3hsdHs9SQrQP: #9fa6ad;
  --plasmic-token-solid-gray-40: var(--token-3hsdHs9SQrQP);
  --token-IjBUITyVGNVJ: #868c93;
  --plasmic-token-solid-gray-50: var(--token-IjBUITyVGNVJ);
  --token-sJr9sbZwbyqK: #59626b;
  --plasmic-token-solid-gray-60: var(--token-sJr9sbZwbyqK);
  --token-6bXw4oz7csc-: #414549;
  --plasmic-token-solid-gray-70: var(--token-6bXw4oz7csc-);
  --token-Uy2x1Cuw6kvC: #2e3235;
  --plasmic-token-solid-gray-80: var(--token-Uy2x1Cuw6kvC);
  --token-x0ltlSxVUZco: #1f2123;
  --plasmic-token-solid-gray-90: var(--token-x0ltlSxVUZco);
  --token-F-isEiSy-7QO: #e1f4ff;
  --plasmic-token-solid-blue-10: var(--token-F-isEiSy-7QO);
  --token-eqvPWoOG9mbc: #8bcdf3;
  --plasmic-token-solid-blue-20: var(--token-eqvPWoOG9mbc);
  --token-pkN2y4iq-viG: #087fb3;
  --plasmic-token-solid-blue-30: var(--token-pkN2y4iq-viG);
  --token-gaowRD3VsD3w: #005a8c;
  --plasmic-token-solid-blue-60: var(--token-gaowRD3VsD3w);
  --token-vF1ggEL4NG72: #003c70;
  --plasmic-token-solid-blue-80: var(--token-vF1ggEL4NG72);
  --token-f0Mcnp3uZyQy: #ffebe8;
  --plasmic-token-solid-coral-05: var(--token-f0Mcnp3uZyQy);
  --token-WnhL5TAc8eef: #ffc7be;
  --plasmic-token-solid-coral-10: var(--token-WnhL5TAc8eef);
  --token-JYEEwSwHupuU: #ff715a;
  --plasmic-token-solid-coral-20: var(--token-JYEEwSwHupuU);
  --token-pmZ4Vyx2r26Q: #d23f10;
  --plasmic-token-solid-coral-30: var(--token-pmZ4Vyx2r26Q);
  --token-M9WLcJW70NrG: #e8ffd7;
  --plasmic-token-solid-green-10: var(--token-M9WLcJW70NrG);
  --token-sfW5F3w_W8A2: #81e733;
  --plasmic-token-solid-green-20: var(--token-sfW5F3w_W8A2);
  --token-rfZ4zZkwVORO: #57b310;
  --plasmic-token-solid-green-30: var(--token-rfZ4zZkwVORO);
  --token-sx8pCYJaanUh: #f6cfaa;
  --plasmic-token-solid-accent-orange-10: var(--token-sx8pCYJaanUh);
  --token-MzxWuds4CjwR: #8e4400;
  --plasmic-token-solid-accent-orange-70: var(--token-MzxWuds4CjwR);
  --token-FAhL2Mfn3yM0: #e3bfff;
  --plasmic-token-solid-accent-violet-10: var(--token-FAhL2Mfn3yM0);
  --token-7ayPLCftgtII: #490083;
  --plasmic-token-solid-accent-violet-70: var(--token-7ayPLCftgtII);
  --token-ga-kY0Io0LEZ: #afd5fc;
  --plasmic-token-solid-accent-blue-10: var(--token-ga-kY0Io0LEZ);
  --token-xCGZz4-mn0RV: #1342e8;
  --plasmic-token-solid-accent-blue-70: var(--token-xCGZz4-mn0RV);
  --token-F1M30cPrvh3r: #a6f7f2;
  --plasmic-token-solid-accent-green-10: var(--token-F1M30cPrvh3r);
  --token-jRMwyvGZK0vW: #006761;
  --plasmic-token-solid-accent-green-70: var(--token-jRMwyvGZK0vW);
  --token-tL4K7mW8qYJH: #fea9f0;
  --plasmic-token-solid-accent-pink-10: var(--token-tL4K7mW8qYJH);
  --token-8RsmlSuL8EPL: #970376;
  --plasmic-token-solid-accent-pink-70: var(--token-8RsmlSuL8EPL);
  --token-16-dlyW8ufwh: #b4ebfa;
  --plasmic-token-solid-accent-turquoise-10: var(--token-16-dlyW8ufwh);
  --token-soMM-WfmvKHK: #1d8ba9;
  --plasmic-token-solid-accent-turquoise-70: var(--token-soMM-WfmvKHK);
  --token-6ihMHiXSX_i2: #bd3308;
  --plasmic-token-solid-coral-40: var(--token-6ihMHiXSX_i2);
  --token-5WeNl9usbH_d: #a7fffc;
  --plasmic-token-solid-primary-05: var(--token-5WeNl9usbH_d);
  --token-iqU3sAfnTefg: #5ed9d6;
  --plasmic-token-solid-primary-10: var(--token-iqU3sAfnTefg);
  --token-ZCY3F4ESGxWk: #00a7a3;
  --plasmic-token-solid-primary-20: var(--token-ZCY3F4ESGxWk);
  --token--gqevcApknJE: #008c88;
  --plasmic-token-solid-primary-30: var(--token--gqevcApknJE);
  --token-ewlegQppfo1J: rgba(0, 103, 100, 1);
  --plasmic-token-solid-primary-40: var(--token-ewlegQppfo1J);
  --token-ioced1ZkDMvE: #005856;
  --plasmic-token-solid-primary-50: var(--token-ioced1ZkDMvE);
  --token-hk2-p1B3ARYw: #004443;
  --plasmic-token-solid-primary-60: var(--token-hk2-p1B3ARYw);
  --token-_CD8D92vD3hA: #003130;
  --plasmic-token-solid-primary-70: var(--token-_CD8D92vD3hA);
  --token-WvJAmfS3B3gH: rgba(0, 34, 34, 1);
  --plasmic-token-solid-primary-80: var(--token-WvJAmfS3B3gH);
  --token-uob3a0bI0eoP: #001b1a;
  --plasmic-token-solid-primary-90: var(--token-uob3a0bI0eoP);
  --token-aKYGyL-1B7v3: #99c9fc;
  --plasmic-token-solid-secondary-05: var(--token-aKYGyL-1B7v3);
  --token-on6uSmFOpxWF: #4696e8;
  --plasmic-token-solid-secondary-10: var(--token-on6uSmFOpxWF);
  --token-PoaUmQohdS1F: #2378ce;
  --plasmic-token-solid-secondary-20: var(--token-PoaUmQohdS1F);
  --token-xQYrBFgQ3pfr: #0b61b9;
  --plasmic-token-solid-secondary-30: var(--token-xQYrBFgQ3pfr);
  --token-o3CfadBKU-1J: #004c9b;
  --plasmic-token-solid-secondary-40: var(--token-o3CfadBKU-1J);
  --token-3P4aM2WzDos2: #003f80;
  --plasmic-token-solid-secondary-50: var(--token-3P4aM2WzDos2);
  --token-LjXCP1lIAacL: #003366;
  --plasmic-token-solid-secondary-60: var(--token-LjXCP1lIAacL);
  --token-_hjyY2bFxFBI: #00274f;
  --plasmic-token-solid-secondary-70: var(--token-_hjyY2bFxFBI);
  --token-lBBcScEFC1iW: #001c38;
  --plasmic-token-solid-secondary-80: var(--token-lBBcScEFC1iW);
  --token-FyWCqlyR3lo9: #001225;
  --plasmic-token-solid-secondary-90: var(--token-FyWCqlyR3lo9);
  --token-o0-GjBbt3YK-: rgba(153, 255, 153, 1);
  --plasmic-token-solid-success-05: var(--token-o0-GjBbt3YK-);
  --token--KmAl11udt8T: rgba(74, 239, 74, 1);
  --plasmic-token-solid-success-10: var(--token--KmAl11udt8T);
  --token-GEhQLaidx1FS: #0fa80f;
  --plasmic-token-solid-success-20: var(--token-GEhQLaidx1FS);
  --token-HSjV8ipmZEwO: #007f00;
  --plasmic-token-solid-success-30: var(--token-HSjV8ipmZEwO);
  --token-GOpcrXPwUwrV: rgba(0, 103, 0, 1);
  --plasmic-token-solid-success-40: var(--token-GOpcrXPwUwrV);
  --token-vFH6HQkY_8eh: #005400;
  --plasmic-token-solid-success-50: var(--token-vFH6HQkY_8eh);
  --token-KzH4_IKzKwxZ: #004300;
  --plasmic-token-solid-success-60: var(--token-KzH4_IKzKwxZ);
  --token-3mvYaNs82Vuz: #003800;
  --plasmic-token-solid-success-70: var(--token-3mvYaNs82Vuz);
  --token-xumLagKFUsyn: rgba(0, 44, 0, 1);
  --plasmic-token-solid-success-80: var(--token-xumLagKFUsyn);
  --token-Xefbqm4rIhAz: rgba(0, 32, 0, 1);
  --plasmic-token-solid-success-90: var(--token-Xefbqm4rIhAz);
  --token-JbV9XhTSzMQw: rgba(255, 236, 136, 1);
  --plasmic-token-solid-notice-05: var(--token-JbV9XhTSzMQw);
  --token-p4v5hRDawSwW: #f5d945;
  --plasmic-token-solid-notice-10: var(--token-p4v5hRDawSwW);
  --token-HAq2NCDlt41h: #edc700;
  --plasmic-token-solid-notice-20: var(--token-HAq2NCDlt41h);
  --token-9hTQS47K3ccW: #ae9200;
  --plasmic-token-solid-notice-30: var(--token-9hTQS47K3ccW);
  --token-pUBn_oMpXTY1: rgba(130, 109, 0, 1);
  --plasmic-token-solid-notice-40: var(--token-pUBn_oMpXTY1);
  --token-iA3C3sAIPSuu: rgba(107, 90, 0, 1);
  --plasmic-token-solid-notice-50: var(--token-iA3C3sAIPSuu);
  --token-ehRnWhYgMmk5: rgba(81, 68, 0, 1);
  --plasmic-token-solid-notice-60: var(--token-ehRnWhYgMmk5);
  --token-mzyFQmlUPXj7: rgba(63, 53, 0, 1);
  --plasmic-token-solid-notice-70: var(--token-mzyFQmlUPXj7);
  --token-517q50vSE7eH: rgba(46, 39, 0, 1);
  --plasmic-token-solid-notice-80: var(--token-517q50vSE7eH);
  --token-UUqIOjeUKAXr: rgba(34, 28, 0, 1);
  --plasmic-token-solid-notice-90: var(--token-UUqIOjeUKAXr);
  --token-cq-Q2eSeG3Ce: rgba(247, 187, 131, 1);
  --plasmic-token-solid-warning-05: var(--token-cq-Q2eSeG3Ce);
  --token-1g9jaWaaNtq_: #f49945;
  --plasmic-token-solid-warning-10: var(--token-1g9jaWaaNtq_);
  --token-iIb1PKIe4dly: rgba(227, 121, 23, 1);
  --plasmic-token-solid-warning-20: var(--token-iIb1PKIe4dly);
  --token-jedE8NGXFVv6: #bb5900;
  --plasmic-token-solid-warning-30: var(--token-jedE8NGXFVv6);
  --token-lhXkBJGFrEFa: rgba(142, 67, 0, 1);
  --plasmic-token-solid-warning-40: var(--token-lhXkBJGFrEFa);
  --token-DOvxw6q7dqRc: rgba(122, 58, 0, 1);
  --plasmic-token-solid-warning-50: var(--token-DOvxw6q7dqRc);
  --token-35V46JVvA2dT: rgba(104, 49, 0, 1);
  --plasmic-token-solid-warning-60: var(--token-35V46JVvA2dT);
  --token-Na4rSAI47HaG: rgba(78, 37, 0, 1);
  --plasmic-token-solid-warning-70: var(--token-Na4rSAI47HaG);
  --token-STWWaxMPN0iq: #341800;
  --plasmic-token-solid-warning-80: var(--token-STWWaxMPN0iq);
  --token-cfpU9OyKs7hR: rgba(41, 19, 0, 1);
  --plasmic-token-solid-warning-90: var(--token-cfpU9OyKs7hR);
  --token-nhed4qAGMWMl: rgba(255, 164, 164, 1);
  --plasmic-token-solid-error-05: var(--token-nhed4qAGMWMl);
  --token-IHOmfBYA37RU: rgba(251, 106, 106, 1);
  --plasmic-token-solid-error-10: var(--token-IHOmfBYA37RU);
  --token-A5SknWmS3YfQ: #ea3232;
  --plasmic-token-solid-error-20: var(--token-A5SknWmS3YfQ);
  --token-wztJeibDmC_Z: #d10000;
  --plasmic-token-solid-error-30: var(--token-wztJeibDmC_Z);
  --token-g9ru0UDFwC4M: #aa0000;
  --plasmic-token-solid-error-40: var(--token-g9ru0UDFwC4M);
  --token-oIYnGCUAN8uS: #940000;
  --plasmic-token-solid-error-50: var(--token-oIYnGCUAN8uS);
  --token-xSdFI7PoMVNo: rgba(109, 0, 0, 1);
  --plasmic-token-solid-error-60: var(--token-xSdFI7PoMVNo);
  --token-YE0gqRfGRIR8: rgba(72, 0, 0, 1);
  --plasmic-token-solid-error-70: var(--token-YE0gqRfGRIR8);
  --token-d5TL7_X6Lviy: #340000;
  --plasmic-token-solid-error-80: var(--token-d5TL7_X6Lviy);
  --token-_HT2Eky_EGvK: #1e0000;
  --plasmic-token-solid-error-90: var(--token-_HT2Eky_EGvK);
  --token-7jALs9WK8ZVb: #42b6d5;
  --plasmic-token-solid-accent-turquoise-30: var(--token-7jALs9WK8ZVb);
  --token-8WwcVVT045jy: rgba(28, 138, 168, 1);
  --plasmic-token-solid-accent-turquoise-40: var(--token-8WwcVVT045jy);
  --token-yRzZdeR-lkVl: rgba(14, 109, 134, 1);
  --plasmic-token-solid-accent-turquoise-50: var(--token-yRzZdeR-lkVl);
  --token-EXzSd5yWEvn4: rgba(19, 65, 231, 1);
  --plasmic-token-solid-accent-navy-30: var(--token-EXzSd5yWEvn4);
  --token-AI6-sOI9SB-A: rgba(6, 47, 189, 1);
  --plasmic-token-solid-accent-navy-40: var(--token-AI6-sOI9SB-A);
  --token-iuqkuN2ViSz8: rgba(6, 41, 166, 1);
  --plasmic-token-solid-accent-navy-50: var(--token-iuqkuN2ViSz8);
  --token-tHiq6SLPN-0m: #d634f0;
  --plasmic-token-solid-accent-magenta-20: var(--token-tHiq6SLPN-0m);
  --token-_xAboZji8WZH: rgba(167, 15, 192, 1);
  --plasmic-token-solid-accent-magenta-30: var(--token-_xAboZji8WZH);
  --token-QwiOfeB6hAnC: rgba(130, 3, 150, 1);
  --plasmic-token-solid-accent-magenta-40: var(--token-QwiOfeB6hAnC);
  --token-bCLjOxICmFcL: rgba(103, 0, 119, 1);
  --plasmic-token-solid-accent-magenta-50: var(--token-bCLjOxICmFcL);
  --token-irQLn6HWxlFL: rgba(84, 0, 98, 1);
  --plasmic-token-solid-accent-magenta-60: var(--token-irQLn6HWxlFL);
  --token-CW-NSLdG88WZ: rgba(238, 238, 238, 1);
  --plasmic-token-solid-gray-05: var(--token-CW-NSLdG88WZ);
  --token-IRCruaPA7hTk: #d6d6d6;
  --plasmic-token-solid-gray-10: var(--token-IRCruaPA7hTk);
  --token-kJFgGuMSNxmW: rgba(183, 183, 183, 1);
  --plasmic-token-solid-gray-20: var(--token-kJFgGuMSNxmW);
  --token-XXsK5Dlj6dvg: #8f8f8f;
  --plasmic-token-solid-gray-30: var(--token-XXsK5Dlj6dvg);
  --token-8UPZ8w-CQsFe: rgba(117, 117, 117, 1);
  --plasmic-token-solid-gray-40: var(--token-8UPZ8w-CQsFe);
  --token-B4M3LC8ZT1uK: #545454;
  --plasmic-token-solid-gray-50: var(--token-B4M3LC8ZT1uK);
  --token-OWojUp3DNW77: rgba(54, 54, 54, 1);
  --plasmic-token-solid-gray-60: var(--token-OWojUp3DNW77);
  --token-RqdYJ6oeMFYq: #282828;
  --plasmic-token-solid-gray-70: var(--token-RqdYJ6oeMFYq);
  --token-lmwh-Kt360Pd: #1e1e1e;
  --plasmic-token-solid-gray-80: var(--token-lmwh-Kt360Pd);
  --token-ehzwoACCT0PU: #171717;
  --plasmic-token-solid-gray-90: var(--token-ehzwoACCT0PU);
}

.plasmic_tokens {
  --plsmc-standard-width: 1152px;
  --plsmc-wide-width: 1152px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-MxgHGUPFzARY_font-family: var(--roboto);
  --mixin-MxgHGUPFzARY_font-size: 16px;
  --mixin-MxgHGUPFzARY_font-weight: 400;
  --mixin-MxgHGUPFzARY_font-style: normal;
  --mixin-MxgHGUPFzARY_color: var(--token-x0ltlSxVUZco);
  --mixin-MxgHGUPFzARY_text-align: left;
  --mixin-MxgHGUPFzARY_text-transform: none;
  --mixin-MxgHGUPFzARY_line-height: 24px;
  --mixin-MxgHGUPFzARY_letter-spacing: normal;
  --mixin-MxgHGUPFzARY_white-space: pre-wrap;
  --mixin-MxgHGUPFzARY_user-select: text;
  --mixin-MxgHGUPFzARY_text-decoration-line: none;
  --mixin-MxgHGUPFzARY_text-overflow: clip;
  --mixin-1bh3XlHrYt27_color: var(--token-x0ltlSxVUZco);
  --mixin-1bh3XlHrYt27_font-weight: 700;
  --mixin-1bh3XlHrYt27_font-size: 32px;
  --mixin-1bh3XlHrYt27_line-height: 48px;
  --mixin-1bh3XlHrYt27_font-family: var(--rubik);
  --mixin-1bh3XlHrYt27_white-space: pre-wrap;
  --mixin-oU3hHmE-P_LX_color: var(--token-x0ltlSxVUZco);
  --mixin-oU3hHmE-P_LX_font-size: 28px;
  --mixin-oU3hHmE-P_LX_font-weight: 700;
  --mixin-oU3hHmE-P_LX_line-height: 40px;
  --mixin-oU3hHmE-P_LX_font-family: var(--rubik);
  --mixin-oU3hHmE-P_LX_white-space: pre-wrap;
  --mixin-8TkpYEsz3Wto_color: var(--token-pkN2y4iq-viG);
  --mixin-8TkpYEsz3Wto_font-family: var(--roboto);
  --mixin-8TkpYEsz3Wto_font-size: 16px;
  --mixin-8TkpYEsz3Wto_line-height: 24px;
  --mixin-8TkpYEsz3Wto_white-space: pre-wrap;
  --mixin-iJZzRc4Lieqr_color: var(--token-x0ltlSxVUZco);
  --mixin-iJZzRc4Lieqr_font-size: 24px;
  --mixin-iJZzRc4Lieqr_font-weight: 600;
  --mixin-iJZzRc4Lieqr_line-height: 32px;
  --mixin-iJZzRc4Lieqr_font-family: var(--rubik);
  --mixin-iJZzRc4Lieqr_white-space: pre-wrap;
  --mixin-kb84McAzt0oK_color: var(--token-x0ltlSxVUZco);
  --mixin-kb84McAzt0oK_font-size: 21px;
  --mixin-kb84McAzt0oK_font-weight: 600;
  --mixin-kb84McAzt0oK_line-height: 28px;
  --mixin-kb84McAzt0oK_font-family: var(--rubik);
  --mixin-kb84McAzt0oK_white-space: pre-wrap;
  --mixin-_LA8PinxOMWK_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-_LA8PinxOMWK_border-bottom-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-bottom-style: solid;
  --mixin-_LA8PinxOMWK_border-bottom-width: 1px;
  --mixin-_LA8PinxOMWK_border-left-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-left-style: solid;
  --mixin-_LA8PinxOMWK_border-left-width: 1px;
  --mixin-_LA8PinxOMWK_border-right-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-right-style: solid;
  --mixin-_LA8PinxOMWK_border-right-width: 1px;
  --mixin-_LA8PinxOMWK_border-top-color: #dddddd;
  --mixin-_LA8PinxOMWK_border-top-style: solid;
  --mixin-_LA8PinxOMWK_border-top-width: 1px;
  --mixin-_LA8PinxOMWK_border-bottom-left-radius: 3px;
  --mixin-_LA8PinxOMWK_border-bottom-right-radius: 3px;
  --mixin-_LA8PinxOMWK_border-top-left-radius: 3px;
  --mixin-_LA8PinxOMWK_border-top-right-radius: 3px;
  --mixin-_LA8PinxOMWK_padding-bottom: 1px;
  --mixin-_LA8PinxOMWK_padding-left: 4px;
  --mixin-_LA8PinxOMWK_padding-right: 4px;
  --mixin-_LA8PinxOMWK_padding-top: 1px;
  --mixin-_LA8PinxOMWK_white-space: pre-wrap;
  --mixin-1zUr_KBU36Gb_border-left-color: #dddddd;
  --mixin-1zUr_KBU36Gb_border-left-style: solid;
  --mixin-1zUr_KBU36Gb_border-left-width: 3px;
  --mixin-1zUr_KBU36Gb_color: #888888;
  --mixin-1zUr_KBU36Gb_padding-left: 10px;
  --mixin-1zUr_KBU36Gb_white-space: pre-wrap;
  --mixin-Tzi7GzKBiu8__background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Tzi7GzKBiu8__border-bottom-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-bottom-style: solid;
  --mixin-Tzi7GzKBiu8__border-bottom-width: 1px;
  --mixin-Tzi7GzKBiu8__border-left-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-left-style: solid;
  --mixin-Tzi7GzKBiu8__border-left-width: 1px;
  --mixin-Tzi7GzKBiu8__border-right-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-right-style: solid;
  --mixin-Tzi7GzKBiu8__border-right-width: 1px;
  --mixin-Tzi7GzKBiu8__border-top-color: #dddddd;
  --mixin-Tzi7GzKBiu8__border-top-style: solid;
  --mixin-Tzi7GzKBiu8__border-top-width: 1px;
  --mixin-Tzi7GzKBiu8__border-bottom-left-radius: 3px;
  --mixin-Tzi7GzKBiu8__border-bottom-right-radius: 3px;
  --mixin-Tzi7GzKBiu8__border-top-left-radius: 3px;
  --mixin-Tzi7GzKBiu8__border-top-right-radius: 3px;
  --mixin-Tzi7GzKBiu8__padding-bottom: 3px;
  --mixin-Tzi7GzKBiu8__padding-left: 6px;
  --mixin-Tzi7GzKBiu8__padding-right: 6px;
  --mixin-Tzi7GzKBiu8__padding-top: 3px;
  --mixin-Tzi7GzKBiu8__white-space: pre-wrap;
  --mixin-KV5A5I_vAiFD_display: flex;
  --mixin-KV5A5I_vAiFD_flex-direction: column;
  --mixin-KV5A5I_vAiFD_align-items: stretch;
  --mixin-KV5A5I_vAiFD_justify-content: flex-start;
  --mixin-KV5A5I_vAiFD_list-style-position: outside;
  --mixin-KV5A5I_vAiFD_padding-left: 40px;
  --mixin-KV5A5I_vAiFD_position: relative;
  --mixin-KV5A5I_vAiFD_list-style-type: disc;
  --mixin-KV5A5I_vAiFD_white-space: pre-wrap;
  --mixin-YqYYrXDnWKMj_display: flex;
  --mixin-YqYYrXDnWKMj_flex-direction: column;
  --mixin-YqYYrXDnWKMj_align-items: stretch;
  --mixin-YqYYrXDnWKMj_justify-content: flex-start;
  --mixin-YqYYrXDnWKMj_list-style-position: outside;
  --mixin-YqYYrXDnWKMj_padding-left: 40px;
  --mixin-YqYYrXDnWKMj_position: relative;
  --mixin-YqYYrXDnWKMj_list-style-type: decimal;
  --mixin-YqYYrXDnWKMj_white-space: pre-wrap;
  --mixin-PEKZ-DU2gF79_color: #000000;
  --mixin-PEKZ-DU2gF79_font-size: 18px;
  --mixin-PEKZ-DU2gF79_font-weight: 600;
  --mixin-PEKZ-DU2gF79_line-height: 24px;
  --mixin-PEKZ-DU2gF79_font-family: var(--rubik);
  --mixin-PEKZ-DU2gF79_white-space: pre-wrap;
  --mixin-YflEUQ8pP7Vs_color: var(--token-x0ltlSxVUZco);
  --mixin-YflEUQ8pP7Vs_font-size: 16px;
  --mixin-YflEUQ8pP7Vs_font-weight: 600;
  --mixin-YflEUQ8pP7Vs_line-height: 24px;
  --mixin-YflEUQ8pP7Vs_font-family: var(--rubik);
  --mixin-YflEUQ8pP7Vs_white-space: pre-wrap;
  --mixin-B5WnqqW-xgKz_color: var(--token-gaowRD3VsD3w);
  --mixin-B5WnqqW-xgKz_white-space: pre-wrap;
  --mixin-zb0yD342SDja_white-space: pre-wrap;
  --mixin-K-TsDPINB79u_font-family: var(--roboto);
  --mixin-K-TsDPINB79u_color: var(--token-x0ltlSxVUZco);
  --mixin-K-TsDPINB79u_font-weight: 500;
  --mixin-K-TsDPINB79u_font-size: 10px;
  --mixin-K-TsDPINB79u_line-height: 12px;
  --mixin-K-TsDPINB79u_white-space: pre-wrap;
  --mixin-_sJ3mZm2Lxao_white-space: pre-wrap;
  --mixin-VGbZIZxq7-kl_white-space: pre-wrap;
  --mixin-fTLgGuWrHW4I_color: var(--token-gaowRD3VsD3w);
  --mixin-fTLgGuWrHW4I_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-ejS-snTcbmmH_white-space: pre-wrap;
  --plasmic-mixin-text-heading-display_white-space: var(
    --mixin-ejS-snTcbmmH_white-space
  );
  --mixin-1SFMDTzfOhd5_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-1_white-space: var(
    --mixin-1SFMDTzfOhd5_white-space
  );
  --mixin-zyrR-poZAR_c_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-2_white-space: var(
    --mixin-zyrR-poZAR_c_white-space
  );
  --mixin-FGv7O3YiNUFK_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-3_white-space: var(
    --mixin-FGv7O3YiNUFK_white-space
  );
  --mixin-qCq7FskmBXbb_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-4_white-space: var(
    --mixin-qCq7FskmBXbb_white-space
  );
  --mixin-6iYzHAY1M8KJ_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-5_white-space: var(
    --mixin-6iYzHAY1M8KJ_white-space
  );
  --mixin-oET7Miu-9uPm_white-space: pre-wrap;
  --plasmic-mixin-text-heading-h-6_white-space: var(
    --mixin-oET7Miu-9uPm_white-space
  );
  --mixin-S4M51aB-IP-Z_white-space: pre-wrap;
  --plasmic-mixin-text-heading-medium_white-space: var(
    --mixin-S4M51aB-IP-Z_white-space
  );
  --mixin-6n5RWR1WNhEr_white-space: pre-wrap;
  --plasmic-mixin-text-heading-small_white-space: var(
    --mixin-6n5RWR1WNhEr_white-space
  );
  --mixin-f6cJEXVuwxPY_white-space: pre-wrap;
  --plasmic-mixin-text-caps-large_white-space: var(
    --mixin-f6cJEXVuwxPY_white-space
  );
  --mixin-op8XvD2NVqnx_white-space: pre-wrap;
  --plasmic-mixin-text-caps-medium_white-space: var(
    --mixin-op8XvD2NVqnx_white-space
  );
  --mixin-g8Cc3dg4jOUv_white-space: pre-wrap;
  --plasmic-mixin-text-caps-small_white-space: var(
    --mixin-g8Cc3dg4jOUv_white-space
  );
  --mixin-LtzLIAjb6hvV_white-space: pre-wrap;
  --plasmic-mixin-text-link-default_white-space: var(
    --mixin-LtzLIAjb6hvV_white-space
  );
  --mixin-2HSXNLyUf7Qs_white-space: pre-wrap;
  --plasmic-mixin-text-link-active_white-space: var(
    --mixin-2HSXNLyUf7Qs_white-space
  );
  --mixin-BNRz1JHb5MoX_white-space: pre-wrap;
  --plasmic-mixin-text-body-lead_white-space: var(
    --mixin-BNRz1JHb5MoX_white-space
  );
  --mixin-Fp22zdN7YJip_white-space: pre-wrap;
  --plasmic-mixin-text-body-large_white-space: var(
    --mixin-Fp22zdN7YJip_white-space
  );
  --mixin-q6jvu0YM_jrD_white-space: pre-wrap;
  --plasmic-mixin-text-body-medium_white-space: var(
    --mixin-q6jvu0YM_jrD_white-space
  );
  --mixin-iR9Mv5PY1rkg_white-space: pre-wrap;
  --plasmic-mixin-text-body-small_white-space: var(
    --mixin-iR9Mv5PY1rkg_white-space
  );
  --mixin-1i1gL_Top87R_box-shadow: inset 0px 1px 4px 2px #0f2a410d;
  --plasmic-mixin-shadow-in-soft_box-shadow: var(
    --mixin-1i1gL_Top87R_box-shadow
  );
  --mixin-1i1gL_Top87R_white-space: pre-wrap;
  --plasmic-mixin-shadow-in-soft_white-space: var(
    --mixin-1i1gL_Top87R_white-space
  );
  --mixin-TlHf5y-0_fAR_box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  --plasmic-mixin-shadow-in-medium_box-shadow: var(
    --mixin-TlHf5y-0_fAR_box-shadow
  );
  --mixin-TlHf5y-0_fAR_white-space: pre-wrap;
  --plasmic-mixin-shadow-in-medium_white-space: var(
    --mixin-TlHf5y-0_fAR_white-space
  );
  --mixin-uczu7e64seZe_box-shadow: inset 0px 1px 4px 2px #0f2a4133;
  --plasmic-mixin-shadow-in-strong_box-shadow: var(
    --mixin-uczu7e64seZe_box-shadow
  );
  --mixin-uczu7e64seZe_white-space: pre-wrap;
  --plasmic-mixin-shadow-in-strong_white-space: var(
    --mixin-uczu7e64seZe_white-space
  );
  --mixin-SjV8dYFe6zND_box-shadow: 0px 2px 8px 0px #0f2a410f,
    0px 1px 2px -1px #0f2a410a;
  --plasmic-mixin-shadow-out-soft_box-shadow: var(
    --mixin-SjV8dYFe6zND_box-shadow
  );
  --mixin-SjV8dYFe6zND_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-soft_white-space: var(
    --mixin-SjV8dYFe6zND_white-space
  );
  --mixin-3VEN6HfO77MD_box-shadow: 0px 2px 12px -2px #0f2a411a,
    0px 1px 4px -2px #0f2a410f;
  --plasmic-mixin-shadow-out-medium_box-shadow: var(
    --mixin-3VEN6HfO77MD_box-shadow
  );
  --mixin-3VEN6HfO77MD_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-medium_white-space: var(
    --mixin-3VEN6HfO77MD_white-space
  );
  --mixin-D1H_d57eKN2E_box-shadow: 0px 4px 20px -4px #0f2a4126,
    0px 2px 6px -3px #0f2a411a;
  --plasmic-mixin-shadow-out-strong_box-shadow: var(
    --mixin-D1H_d57eKN2E_box-shadow
  );
  --mixin-D1H_d57eKN2E_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-strong_white-space: var(
    --mixin-D1H_d57eKN2E_white-space
  );
  --mixin-u8BHzGi8FDHG_box-shadow: 0px 4px 32px -4px #0f2a4133,
    0px 2px 8px -4px #0f2a4133;
  --plasmic-mixin-shadow-out-x-strong_box-shadow: var(
    --mixin-u8BHzGi8FDHG_box-shadow
  );
  --mixin-u8BHzGi8FDHG_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-x-strong_white-space: var(
    --mixin-u8BHzGi8FDHG_white-space
  );
  --mixin-vw1hWUQq-b6q_white-space: pre-wrap;
  --plasmic-mixin-meta-subtitle_white-space: var(
    --mixin-vw1hWUQq-b6q_white-space
  );
  --mixin-I37VtYFn1Nth_white-space: pre-wrap;
  --plasmic-mixin-pattern-light-gray_white-space: var(
    --mixin-I37VtYFn1Nth_white-space
  );
  --mixin-l1PL3lWAWa4z_white-space: pre-wrap;
  --plasmic-mixin-gradient-blue-linear-10_white-space: var(
    --mixin-l1PL3lWAWa4z_white-space
  );
  --mixin-x9Zmd4XfsOhN_white-space: pre-wrap;
  --plasmic-mixin-gradient-blue-linear-30_white-space: var(
    --mixin-x9Zmd4XfsOhN_white-space
  );
  --mixin-BgZ72nf0Q95u_white-space: pre-wrap;
  --plasmic-mixin-text-link-hover_white-space: var(
    --mixin-BgZ72nf0Q95u_white-space
  );
  --mixin-gpSh2J4DL4gt_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-h-1_white-space: var(
    --mixin-gpSh2J4DL4gt_white-space
  );
  --mixin-u3qvt-DaSSFO_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-h-5_white-space: var(
    --mixin-u3qvt-DaSSFO_white-space
  );
  --mixin-1Ex1aJf_jLI-_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-h-6_white-space: var(
    --mixin-1Ex1aJf_jLI-_white-space
  );
  --mixin-21eCexhoWSac_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-heading-medium_white-space: var(
    --mixin-21eCexhoWSac_white-space
  );
  --mixin-66x0oN99QXly_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-body-large_white-space: var(
    --mixin-66x0oN99QXly_white-space
  );
  --mixin-RfHrxQ3jgh0O_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-body-medium_white-space: var(
    --mixin-RfHrxQ3jgh0O_white-space
  );
  --mixin-u8QNt7vO8g3d_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-body-small_white-space: var(
    --mixin-u8QNt7vO8g3d_white-space
  );
  --mixin-Hrt0qE7P6LgD_white-space: pre-wrap;
  --plasmic-mixin-text-mockup-caps-medium_white-space: var(
    --mixin-Hrt0qE7P6LgD_white-space
  );
  --mixin-EFd94sJVKfni_box-shadow: 0px 32px 192px -128px #0000001a,
    0px 48px 128px -64px #32415d1a;
  --plasmic-mixin-shadow-out-xx-strong_box-shadow: var(
    --mixin-EFd94sJVKfni_box-shadow
  );
  --mixin-EFd94sJVKfni_white-space: pre-wrap;
  --plasmic-mixin-shadow-out-xx-strong_white-space: var(
    --mixin-EFd94sJVKfni_white-space
  );
  --mixin-A5Agq1fjfvUr_white-space: pre-wrap;
  --plasmic-mixin-gradient-light-gray_white-space: var(
    --mixin-A5Agq1fjfvUr_white-space
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-MxgHGUPFzARY_font-family);
  font-size: var(--mixin-MxgHGUPFzARY_font-size);
  font-weight: var(--mixin-MxgHGUPFzARY_font-weight);
  font-style: var(--mixin-MxgHGUPFzARY_font-style);
  color: var(--mixin-MxgHGUPFzARY_color);
  text-align: var(--mixin-MxgHGUPFzARY_text-align);
  text-transform: var(--mixin-MxgHGUPFzARY_text-transform);
  line-height: var(--mixin-MxgHGUPFzARY_line-height);
  letter-spacing: var(--mixin-MxgHGUPFzARY_letter-spacing);
  white-space: var(--mixin-MxgHGUPFzARY_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-1bh3XlHrYt27_color);
  font-weight: var(--mixin-1bh3XlHrYt27_font-weight);
  font-size: var(--mixin-1bh3XlHrYt27_font-size);
  line-height: var(--mixin-1bh3XlHrYt27_line-height);
  font-family: var(--mixin-1bh3XlHrYt27_font-family);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-oU3hHmE-P_LX_color);
  font-size: var(--mixin-oU3hHmE-P_LX_font-size);
  font-weight: var(--mixin-oU3hHmE-P_LX_font-weight);
  line-height: var(--mixin-oU3hHmE-P_LX_line-height);
  font-family: var(--mixin-oU3hHmE-P_LX_font-family);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-8TkpYEsz3Wto_color);
  font-family: var(--mixin-8TkpYEsz3Wto_font-family);
  font-size: var(--mixin-8TkpYEsz3Wto_font-size);
  line-height: var(--mixin-8TkpYEsz3Wto_line-height);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-iJZzRc4Lieqr_color);
  font-size: var(--mixin-iJZzRc4Lieqr_font-size);
  font-weight: var(--mixin-iJZzRc4Lieqr_font-weight);
  line-height: var(--mixin-iJZzRc4Lieqr_line-height);
  font-family: var(--mixin-iJZzRc4Lieqr_font-family);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-kb84McAzt0oK_color);
  font-size: var(--mixin-kb84McAzt0oK_font-size);
  font-weight: var(--mixin-kb84McAzt0oK_font-weight);
  line-height: var(--mixin-kb84McAzt0oK_line-height);
  font-family: var(--mixin-kb84McAzt0oK_font-family);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  border-radius: var(--mixin-_LA8PinxOMWK_border-top-left-radius)
    var(--mixin-_LA8PinxOMWK_border-top-right-radius)
    var(--mixin-_LA8PinxOMWK_border-bottom-right-radius)
    var(--mixin-_LA8PinxOMWK_border-bottom-left-radius);
  padding: var(--mixin-_LA8PinxOMWK_padding-top)
    var(--mixin-_LA8PinxOMWK_padding-right)
    var(--mixin-_LA8PinxOMWK_padding-bottom)
    var(--mixin-_LA8PinxOMWK_padding-left);
  border-top: var(--mixin-_LA8PinxOMWK_border-top-width)
    var(--mixin-_LA8PinxOMWK_border-top-style)
    var(--mixin-_LA8PinxOMWK_border-top-color);
  border-right: var(--mixin-_LA8PinxOMWK_border-right-width)
    var(--mixin-_LA8PinxOMWK_border-right-style)
    var(--mixin-_LA8PinxOMWK_border-right-color);
  border-bottom: var(--mixin-_LA8PinxOMWK_border-bottom-width)
    var(--mixin-_LA8PinxOMWK_border-bottom-style)
    var(--mixin-_LA8PinxOMWK_border-bottom-color);
  border-left: var(--mixin-_LA8PinxOMWK_border-left-width)
    var(--mixin-_LA8PinxOMWK_border-left-style)
    var(--mixin-_LA8PinxOMWK_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-1zUr_KBU36Gb_color);
  padding-left: var(--mixin-1zUr_KBU36Gb_padding-left);
  border-left: var(--mixin-1zUr_KBU36Gb_border-left-width)
    var(--mixin-1zUr_KBU36Gb_border-left-style)
    var(--mixin-1zUr_KBU36Gb_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  border-radius: var(--mixin-Tzi7GzKBiu8__border-top-left-radius)
    var(--mixin-Tzi7GzKBiu8__border-top-right-radius)
    var(--mixin-Tzi7GzKBiu8__border-bottom-right-radius)
    var(--mixin-Tzi7GzKBiu8__border-bottom-left-radius);
  padding: var(--mixin-Tzi7GzKBiu8__padding-top)
    var(--mixin-Tzi7GzKBiu8__padding-right)
    var(--mixin-Tzi7GzKBiu8__padding-bottom)
    var(--mixin-Tzi7GzKBiu8__padding-left);
  border-top: var(--mixin-Tzi7GzKBiu8__border-top-width)
    var(--mixin-Tzi7GzKBiu8__border-top-style)
    var(--mixin-Tzi7GzKBiu8__border-top-color);
  border-right: var(--mixin-Tzi7GzKBiu8__border-right-width)
    var(--mixin-Tzi7GzKBiu8__border-right-style)
    var(--mixin-Tzi7GzKBiu8__border-right-color);
  border-bottom: var(--mixin-Tzi7GzKBiu8__border-bottom-width)
    var(--mixin-Tzi7GzKBiu8__border-bottom-style)
    var(--mixin-Tzi7GzKBiu8__border-bottom-color);
  border-left: var(--mixin-Tzi7GzKBiu8__border-left-width)
    var(--mixin-Tzi7GzKBiu8__border-left-style)
    var(--mixin-Tzi7GzKBiu8__border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-KV5A5I_vAiFD_display);
  flex-direction: var(--mixin-KV5A5I_vAiFD_flex-direction);
  align-items: var(--mixin-KV5A5I_vAiFD_align-items);
  justify-content: var(--mixin-KV5A5I_vAiFD_justify-content);
  list-style-position: var(--mixin-KV5A5I_vAiFD_list-style-position);
  padding-left: var(--mixin-KV5A5I_vAiFD_padding-left);
  position: var(--mixin-KV5A5I_vAiFD_position);
  list-style-type: var(--mixin-KV5A5I_vAiFD_list-style-type);
  flex-column-gap: var(--mixin-KV5A5I_vAiFD_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-YqYYrXDnWKMj_display);
  flex-direction: var(--mixin-YqYYrXDnWKMj_flex-direction);
  align-items: var(--mixin-YqYYrXDnWKMj_align-items);
  justify-content: var(--mixin-YqYYrXDnWKMj_justify-content);
  list-style-position: var(--mixin-YqYYrXDnWKMj_list-style-position);
  padding-left: var(--mixin-YqYYrXDnWKMj_padding-left);
  position: var(--mixin-YqYYrXDnWKMj_position);
  list-style-type: var(--mixin-YqYYrXDnWKMj_list-style-type);
  flex-column-gap: var(--mixin-YqYYrXDnWKMj_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-PEKZ-DU2gF79_color);
  font-size: var(--mixin-PEKZ-DU2gF79_font-size);
  font-weight: var(--mixin-PEKZ-DU2gF79_font-weight);
  line-height: var(--mixin-PEKZ-DU2gF79_line-height);
  font-family: var(--mixin-PEKZ-DU2gF79_font-family);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-YflEUQ8pP7Vs_color);
  font-size: var(--mixin-YflEUQ8pP7Vs_font-size);
  font-weight: var(--mixin-YflEUQ8pP7Vs_font-weight);
  line-height: var(--mixin-YflEUQ8pP7Vs_line-height);
  font-family: var(--mixin-YflEUQ8pP7Vs_font-family);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-B5WnqqW-xgKz_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
  font-family: var(--mixin-K-TsDPINB79u_font-family);
  color: var(--mixin-K-TsDPINB79u_color);
  font-weight: var(--mixin-K-TsDPINB79u_font-weight);
  font-size: var(--mixin-K-TsDPINB79u_font-size);
  line-height: var(--mixin-K-TsDPINB79u_line-height);
}

:where(.root_reset) h2:where(.h2):hover,
h2:where(.root_reset.h2):hover,
:where(.root_reset .__wab_expr_html_text) h2:hover,
:where(.root_reset_tags) h2:hover,
h2:where(.root_reset_tags):hover {
}

:where(.root_reset) h4:where(.h4):hover,
h4:where(.root_reset.h4):hover,
:where(.root_reset .__wab_expr_html_text) h4:hover,
:where(.root_reset_tags) h4:hover,
h4:where(.root_reset_tags):hover {
}

:where(.root_reset) a:where(.a):active,
a:where(.root_reset.a):active,
:where(.root_reset .__wab_expr_html_text) a:active,
:where(.root_reset_tags) a:active,
a:where(.root_reset_tags):active {
  color: var(--mixin-fTLgGuWrHW4I_color);
}
